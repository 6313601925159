<template>
  <div class="view-fullscreen has-navbar has-footer position-relative layout-split-side">
    <div class="side-right">
      <div
        class="side-right-header bg-dark font-weight-bold shadow-sm d-flex justify-content-between align-items-center border-top border-default-dark">
        <ul class="nav site-nav h-100">
          <li class="nav-item" :class="{
            active: currentCategory && category.name == currentCategory.name,
          }" v-for="category in categories.filter((c) => c.show)" :key="category.name">
            <button class="btn h-100 font-weight-bold" @click="currentCategory = category">
              <!-- <img :src="category.icon" alt="icon" class="icon" /> -->
              <i class="fas icon" :class="`fa-${category.icon}`" />
              <span class="text">{{ category.label }}</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="side-right-content">
        <temporal v-if="currentCategory && currentCategory.name == 'temporal' && site" :data="site"
          @toggle-spinner="toggleSpinner" />
        <site-gallery v-else-if="currentCategory && currentCategory.name == 'media_gallery'" :siteName="site.name"
          @toggle-spinner="toggleSpinner" />
        <files v-else-if="currentCategory && currentCategory.name == 'files'" :siteName="site.name" :site="site"
          @toggle-spinner="toggleSpinner" />
        <point-cloud v-else-if="
          currentCategory && currentCategory.name == 'point_cloud' && site
        " @update-site-data="updateSiteData" :siteId="site._id" :data="site.point_cloud"
          @toggle-spinner="toggleSpinner" />
        <orthomosaic v-else-if="
          currentCategory && currentCategory.name == 'orthomosaic' && site
        " :data="site" @update-site-data="updateSiteData" @toggle-spinner="toggleSpinner" />
        <report v-else-if="currentCategory && currentCategory.name == 'report'" :data="site"
          @toggle-spinner="toggleSpinner" />
      </div>
    </div>
    <spinner :show="isLoading" />
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import Files from "@/components/site/Files.vue";
import SiteGallery from "@/components/site/Gallery.vue";
import Orthomosaic from "@/components/site/Orthomosaic.vue";
import PointCloud from "@/components/site/PointCloud.vue";
import Temporal from "@/components/site/Temporal.vue";
import Report from "@/components/site/Report.vue";

export default {
  name: "view-site",
  components: {
    Spinner,
    SiteGallery,
    Files,
    Orthomosaic,
    PointCloud,
    Temporal,
    Report,
  },
  data() {
    return {
      isLoading: false,
      site: null,
      currentCategory: null,
      categories: [
        {
          label: "Temporal",
          name: "temporal",
          // icon: require("@/assets/icons/temporal.svg"),
          icon: "stopwatch",
          show: true,
        },
        {
          label: "Orthomosaic",
          name: "orthomosaic",
          // icon: require("@/assets/icons/grid.png"),
          icon: "th-large",
          show: true,
        },
        {
          label: "3D Model",
          name: "point_cloud",
          // icon: require("@/assets/icons/3d.png"),
          icon: "cube",
          show: true,
        },
        {
          label: "Reports",
          name: "report",
          icon: "file-contract",
          show: true,
        },
        {
          label: "Media Gallery",
          name: "media_gallery",
          // icon: require("@/assets/icons/media.png"),
          icon: "image",
          show: true,
        },
        {
          label: "Files",
          name: "files",
          // icon: require("@/assets/icons/media.png"),
          icon: "file-pdf",
          show: true,
        },
      ],
    };
  },
  watch: {
    "$route.params": {
      handler() {
        if (this.$route.params && this.$route.params.id) {
          this.getSite();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateSiteData(e) {
      if (e && e.field) {
        if (e.type == "push") {
          this.site[e.field].push(e.data);
        } else if (e.type == "put") {
          const fieldData = JSON.parse(JSON.stringify(this.site[e.field]));
          const dataIndex = this.site[e.field].findIndex(
            (d) => d.id == e.data.id
          );
          let data = this.site[e.field].find((d) => d.id == e.data.id);

          if (!data) return;

          data = e.data;

          fieldData[dataIndex] = data;

          this.$set(this.site, e.field, fieldData);
        } else {
          this.site[e.field] = e.data;
        }
      }
    },
    toggleSpinner(e) {
      this.isLoading = e;
    },
    async getSite() {
      this.isLoading = true;

      const [call, err] = await this.Helper.handle(
        this.API.get(`sites/${this.$route.params.id}`)
      );

      if (!err && call.status == 200) {
        this.site = call.data;

        const hiddenTabs = this.site.hidden_tabs || [];

        this.categories.forEach((category) => {
          if (hiddenTabs.includes(category.name)) {
            category.show = false;
          } else {
            category.show = true;
          }
        });
      }

      this.currentCategory = this.categories.filter((c) => c.show)[0];

      this.isLoading = false;
    },
  },
};
</script>