<template>
  <div class="site-temporal position-relative container-fluid">
    <div id="temporal-map" class="w-100 h-100"></div>
    <div class="map-timeline" v-if="mapLayers.length > 0">
      <!-- <div class="timeline-line"></div> -->
      <button
        class="btn timeline-item user-select-none"
        :class="
          layer.show ? 'bg-dark text-default' : 'bg-muted text-dark semitrans'
        "
        v-for="layer in mapLayers"
        :key="layer.id"
        @click="toggleTileVisibility(layer.id)"
        data-toggle="tooltip"
        :title="layer.name"
        @mouseenter="Helper.showTooltip($event)"
      >
        <template v-if="layer.date">
          {{ Helper.formatDate(layer.date, "MM") }}
          <hr />
          {{ Helper.formatDate(layer.date, "YY") }}
        </template>
        <div class="w-100 text-nowrap text-truncate text-center" v-else>
          {{ layer.name }}
        </div>
      </button>
    </div>
    <div class="map-floating-button" v-if="showMapButton">
      <button
        class="btn shadow"
        :class="isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'"
        data-toggle="tooltip"
        title="Toggle satellite view"
        @mouseenter="Helper.showTooltip($event)"
        @click="isSatelliteView = !isSatelliteView"
      >
        <i class="fas fa-satellite"></i>
      </button>
    </div>
    <button
      class="btn bg-dark border border-default-dark text-default floating-card-toggler"
      :class="{ hide: !hideFloatingCard }"
      @click="hideFloatingCard = false"
    >
      <i class="fas fa-caret-right mr-2" />
      Show
    </button>
    <div class="temporal-floating-card" :class="{ hide: hideFloatingCard }">
      <div class="text-right pb-3">
        <button
          class="btn bg-dark border border-default-dark text-default"
          @click="hideFloatingCard = true"
        >
          <i class="fas fa-times mr-2" />
          Hide
        </button>
      </div>
      <div class="content rounded p-3 mb-3 bg-dark">
        <p
          class="p-3 mb-0 bg-default-dark border border-dark font-weight-bold text-default-dark text-center text-uppercase"
        >
          {{ data.name }}
        </p>
        <table class="table table-borderless mb-0">
          <tbody>
            <tr>
              <td class="font-weight-medium text-default">Site Code</td>
              <td class="text-default-dark">{{ data.site_code }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Location</td>
              <td class="text-default-dark">
                {{ Helper.formatCoordinate([data.lat, data.lng]) }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Region</td>
              <td class="text-default-dark">
                {{ data.region && data.region.name }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Permit Date</td>
              <td class="text-default-dark">
                {{ Helper.formatDate(data.permit_date) }}
                <span v-if="data.permit_date_end">
                  -<br />{{ Helper.formatDate(data.permit_date_end) }}</span
                >
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">LUAS Permit Date</td>
              <td class="text-default-dark">
                {{ Helper.formatDate(data.luas_permit_date) }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Contractor</td>
              <td class="text-default-dark">{{ data.contractor?.name }}</td>
            </tr>
            <!-- <tr>
              <td class="font-weight-medium text-default">Product</td>
              <td class="text-default-dark">{{ data.product }}</td>
            </tr> -->
            <tr>
              <td class="font-weight-medium text-default">Total Acreage</td>
              <td class="text-default-dark">{{ data.total_acreage }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Reserve</td>
              <td class="text-default-dark">{{ data.reserve_land }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium text-default">Balance</td>
              <td class="text-default-dark">{{ data.remaining_land }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <site-weather :site="data" />
    </div>
    <media-preview
      v-if="currentMedia"
      :title="
        currentMedia.title ? currentMedia.title : `${data.name} Standoff Shot`
      "
      :subtitle="currentMedia.subtitle ? currentMedia.subtitle : null"
      :media="currentMedia.data ? currentMedia.data : currentMedia"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import $ from "jquery";
import Moment from "moment";
import MediaPreview from "@/components/MediaPreview";
import SiteWeather from "@/components/SiteWeather";
import PolygonPopup from "@/components/PolygonPopup";
import Vue from "vue";

export default {
  name: "component-site-temporal",
  props: ["data"],
  components: {
    MediaPreview,
    SiteWeather,
  },
  data() {
    return {
      hideFloatingCard: true,
      showMapButton: false,
      isSatelliteView: false,
      map: null,
      mapLayers: [],
      standoffShots: [],
      polygons: [],
      currentMedia: false,
    };
  },
  watch: {
    isSatelliteView: {
      handler() {
        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);
        }
      },
      immediate: false,
    },
    data: {
      handler() {
        this.mapLayers = [];

        if (this.data) {
          this.getStandoffShots();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addPolygonToMap(date) {
      const layerDate = this.Helper.formatDate(date, "MMYYYY");

      const polygonLayers =
        this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes("polygonLayerFill")) ||
        [];

      this.polygons.forEach((polygon) => {
        if (
          polygonLayers
            .map((layer) => layer.id)
            .includes(`polygonLayerFill-${polygon._id}`)
        ) {
          this.map.setLayoutProperty(
            `polygonLayerFill-${polygon._id}`,
            "visibility",
            this.Helper.formatDate(polygon.date, "MMYYYY") == layerDate
              ? "visible"
              : "none"
          );

          this.map.setLayoutProperty(
            `polygonLayerOutline-${polygon._id}`,
            "visibility",
            this.Helper.formatDate(polygon.date, "MMYYYY") == layerDate
              ? "visible"
              : "none"
          );
        } else {
          if (
            polygon.features &&
            this.Helper.formatDate(polygon.date, "MMYYYY") == layerDate
          ) {
            this.map.addSource(`polygonSource-${polygon._id}`, {
              type: "geojson",
              data: polygon.features,
            });

            this.map.addLayer({
              id: `polygonLayerFill-${polygon._id}`,
              type: "fill",
              source: `polygonSource-${polygon._id}`,
              layout: {},
              paint: {
                "fill-color": "#0080ff",
                "fill-opacity": 0.5,
              },
            });

            this.map.addLayer({
              id: `polygonLayerOutline-${polygon._id}`,
              type: "line",
              source: `polygonSource-${polygon._id}`,
              layout: {},
              paint: {
                "line-color": "#000",
                "line-width": 3,
              },
            });

            this.map.on("click", `polygonLayerFill-${polygon._id}`, (e) => {
              this.showPolygonPopup(e, polygon);
            });

            this.map.on("mouseenter", `polygonLayerFill-${polygon._id}`, () => {
              this.map.getCanvas().style.cursor = "pointer";
            });

            this.map.on("mouseleave", `polygonLayerFill-${polygon._id}`, () => {
              this.map.getCanvas().style.cursor = "";
            });
          }
        }
      });
    },
    showPolygonPopup(e, polygon) {
      const PolygonPopupClass = Vue.extend(PolygonPopup);

      const popup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(`<div id="polygon-popup-content-${polygon._id}"></div>`)
        .addTo(this.map);

      const vm = this;

      const popupInstance = new PolygonPopupClass({
        propsData: {
          data: polygon,
          nonInteractive: true,
        },
        methods: {
          deletePolygon(e) {
            if (e) {
              vm.deletePolygon(e, popup);
            }
          },
        },
      });

      popupInstance.$mount(`#polygon-popup-content-${polygon._id}`);

      popup._update();
    },
    addStandoffShotToMap(date) {
      const layerDate = this.Helper.formatDate(date, "MMYYYY");

      const shotLayers =
        this.map
          .getStyle()
          .layers.filter((layer) => layer.id.includes("standoffLayer-")) || [];

      this.standoffShots.forEach((shot) => {
        if (
          shotLayers
            .map((layer) => layer.id)
            .includes(`standoffLayer-${shot._id}`)
        ) {
          this.map.setLayoutProperty(
            `standoffLayer-${shot._id}`,
            "visibility",
            this.Helper.formatDate(shot.date, "MMYYYY") == layerDate
              ? "visible"
              : "none"
          );
        } else {
          if (
            shot.lng &&
            shot.lat &&
            this.Helper.formatDate(shot.date, "MMYYYY") == layerDate
          ) {
            this.map.addSource(`standoffSource-${shot._id}`, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [shot.lng, shot.lat],
                    },
                  },
                ],
              },
            });

            this.map.addLayer({
              id: `standoffLayer-${shot._id}`,
              type: "symbol",
              source: `standoffSource-${shot._id}`,
              layout: {
                "icon-image": "drone",
                "icon-size": 1,
                "icon-rotate": shot.bearing || 0,
                "icon-allow-overlap": true,
              },
            });

            this.map.on("click", `standoffLayer-${shot._id}`, () => {
              this.currentMedia = {
                title: shot.name,
                subtitle: shot.date,
                data: shot.media,
              };
            });

            this.map.on("mouseenter", `standoffLayer-${shot._id}`, () => {
              this.map.getCanvas().style.cursor = "pointer";
            });

            this.map.on("mouseleave", `standoffLayer-${shot._id}`, () => {
              this.map.getCanvas().style.cursor = "";
            });
          }
        }
      });
    },
    async getStandoffShots() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(`site-standoffs?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.standoffShots = call.data;
      }

      this.getPolygons();
    },
    async getPolygons() {
      const [call, err] = await this.Helper.handle(
        this.API.get(`site-polygons?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.polygons = call.data.map((data) => {
          data.id = `polygonLayer:type-${data._id}`;

          return data;
        });
      }

      this.$nextTick(() => {
        this.initMap();
      });
    },
    toggleTileVisibility(layerId) {
      this.mapLayers.forEach((layer) => {
        layer.show = layer.id == layerId ? true : false;

        this.map.setPaintProperty(
          layer.id,
          "raster-opacity",
          layer.id == layerId ? 1 : 0
        );

        if (layer.id == layerId) {
          this.addStandoffShotToMap(layer.date);
          this.addPolygonToMap(layer.date);
        }
      });
    },
    addMapLayers() {
      const layers = this.map
        .getStyle()
        .layers.filter(
          (layer) =>
            layer.id.includes("tile-") ||
            layer.id.includes("standoffLayer-") ||
            layer.id.includes("polygonLayer")
        );

      const sources = Object.keys(this.map.getStyle().sources).filter(
        (source) =>
          source.includes("tile-") ||
          source.includes("standoffSource-") ||
          source.includes("polygonSource-")
      );

      if (layers && layers.length > 0) {
        layers.forEach((layer) => {
          this.map.removeLayer(layer.id);
        });
      }

      if (sources && sources.length > 0) {
        sources.forEach((source) => {
          this.map.removeSource(source);
        });
      }

      this.mapLayers = [];

      if (this.data.tiles && this.data.tiles.length > 0) {
        this.map.loadImage(
          require("@/assets/icons/drone.png"),
          (error, image) => {
            if (error) throw error;

            this.map.addImage("drone", image);

            // Add tiles
            this.data.tiles.forEach((tile, index) => {
              this.API.testExternal(tile.url.concat("index.html"))
                .then(() => {
                  this.map.addSource(`tile-${index}`, {
                    type: "raster",
                    tiles: [`${tile.url}{z}/{x}/{y}.png`],
                    tileSize: 256,
                    attribution:
                      'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
                  });

                  this.map.addLayer({
                    id: `tile-${index}`,
                    type: "raster",
                    source: `tile-${index}`,
                  });

                  this.mapLayers.push({
                    id: `tile-${index}`,
                    name: tile.name,
                    date: tile.date,
                    show: false,
                  });

                  this.mapLayers = this.mapLayers.sort((a, b) => {
                    const aDate = a && a.date ? Moment(a.date).valueOf() : 0;
                    const bDate = b && b.date ? Moment(b.date).valueOf() : 0;

                    return aDate - bDate;
                  });

                  if (this.mapLayers.length > 0) {
                    this.toggleTileVisibility(
                      this.mapLayers[this.mapLayers.length - 1].id
                    );

                    this.$nextTick(() => {
                      const elem = $("#timeline-item-container");

                      if (elem && elem.length > 0) {
                        elem.scrollLeft(elem[0].scrollWidth);
                      }
                    });
                  }
                })
                .catch(() => {
                  console.error(`Invalid Tile URL: ${tile.url}`);
                });
            });
          }
        );
      }
    },
    initMap() {
      return new Promise(async (resolve) => {
        this.$emit("toggle-spinner", true);

        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        let mapCenter = [101.5179483, 3.075603444131706];

        if (this.data.lat && this.data.lng) {
          mapCenter = [this.data.lng, this.data.lat];
        }

        this.map = new mapboxgl.Map({
          container: "temporal-map",
          style: this.Helper.mapStyle(),
          center: mapCenter,
          zoom: 15,
        });

        this.map.on("load", async () => {
          this.showMapButton = true;

          resolve();
        });

        this.map.on("style.load", async () => {
          this.addMapLayers();

          this.$emit("toggle-spinner", false);
        });
      });
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();

      this.map = null;
    }
  },
};
</script>