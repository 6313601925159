<template>
  <div class="site-gallery container-fluid pt-0">
    <div class="gallery-group position-relative">
      <template v-if="medias && medias.length > 0">
        <div
          class="
            px-2
            pt-3
            pb-3
            w-100
            bg-default-dark
            sticky-top
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <p class="mb-0 text-default-dark">
            <template v-if="!isSelectingMedia">
              [{{ medias.filter((m) => m.mime.includes("image")).length }}
              Image,
              {{ medias.filter((m) => m.mime.includes("video")).length }}
              Video]
            </template>
            <template v-else>
              {{ medias.filter((m) => m.selected).length }} Media Selected
            </template>
          </p>
          <button
            class="btn btn-sm btn-primary"
            @click="isSelectingMedia = true"
            v-if="!isSelectingMedia"
          >
            Select Media
          </button>
          <div v-else>
            <template v-if="medias.filter((m) => m.selected).length > 0">
              <button
                class="btn btn-sm btn-info"
                @click="editMedia()"
                title="Edit selected media"
                data-toggle="tooltip"
                @mouseenter="Helper.showTooltip($event)"
              >
                <i class="fas fa-pencil-alt" />
              </button>
              <button
                class="btn btn-sm btn-danger mx-2"
                @click="removeMedia()"
                title="Delete selected media"
                data-toggle="tooltip"
                @mouseenter="Helper.showTooltip($event)"
              >
                <i class="fas fa-trash-alt" />
              </button>
            </template>
            <button class="btn btn-sm btn-light" @click="cancelSelectMedia()">
              Cancel
            </button>
          </div>
        </div>
        <div
          class="gallery-item rounded clickable position-relative hover-parent"
          v-for="media in medias"
          :key="media._id"
          @click="selectMedia(media)"
        >
          <div
            class="overlay-item text-center text-nowrap text-truncate"
            v-if="media.caption"
            :title="media.caption"
            data-toggle="tooltip"
            @mouseenter="Helper.showTooltip($event)"
            style="bottom: 0.5rem; width: calc(100% - 1rem); left: 0.5rem"
          >
            {{ media.caption }}
          </div>
          <button
            class="btn btn-sm btn-danger position-absolute hover-item"
            style="top: 1rem; right: 1rem; z-index: 2"
            @click.stop="removeMedia(media)"
          >
            <i class="fas fa-trash-alt" />
          </button>
          <button
            class="btn btn-sm btn-light position-absolute border shadow-sm"
            style="top: 1rem; right: 1rem; z-index: 2"
            @click.stop="selectMedia(media)"
            v-if="isSelectingMedia"
          >
            <i
              class="fas"
              :class="
                medias
                  .filter((m) => m.selected)
                  .map((m) => m._id)
                  .includes(media._id)
                  ? 'fa-check text-success'
                  : 'fa-square text-light'
              "
            />
          </button>
          <div class="embed-responsive rounded embed-responsive-1by1 shadow-sm">
            <img
              :src="
                Helper.formatMediaUrl(
                  media.formats && media.formats.small
                    ? media.formats.small
                    : media
                )
              "
              class="embed-responsive-item rounded"
              v-if="media.mime.includes('image')"
            />
            <div
              class="
                embed-responsive-item
                rounded
                d-flex
                flex-column
                justify-content-center
                align-items-center
                font-weight-medium
                bg-light
              "
              v-else-if="media.mime.includes('video')"
            >
              <img src="@/assets/icons/camcoder.svg" class="icon-sm mb-2" />
              VIDEO
            </div>
          </div>
        </div>
      </template>
      <div class="col py-5 text-center" v-else>No image found</div>
    </div>
    <media-preview
      v-if="currentMedia"
      :title="`${siteName} Media Gallery`"
      :subtitle="currentMedia.caption"
      :media="currentMedia"
      :hasNavigation="hasNavigation"
      @navigate="navigate"
      @close="currentMedia = null"
    />
    <media-uploader
      v-if="showMediaUploader"
      title="Upload Gallery Media"
      :parentId="$route.params.id"
      parentEndpoint="site-galleries"
      refName="site-gallery"
      :refId="gallery ? gallery._id : null"
      field="media"
      @close="closeMediaUploader"
    />
    <button
      v-if="!isSelectingMedia"
      class="btn btn-fab btn-primary shadow"
      @click="showMediaUploader = true"
    >
      <i class="fas fa-plus" />
    </button>
  </div>
</template>

<script>
import MediaPreview from "@/components/MediaPreview";
import MediaUploader from "@/components/MediaUploader";
import Swal from "sweetalert2";

export default {
  name: "component-site-gallery",
  props: ["siteName"],
  components: {
    MediaPreview,
    MediaUploader,
  },
  data() {
    return {
      gallery: null,
      medias: [],
      currentMedia: null,
      showMediaUploader: false,
      isSelectingMedia: false,
    };
  },
  computed: {
    currentMediaIndex() {
      if (this.medias && this.currentMedia) {
        return this.medias.findIndex(
          (media) => media._id == this.currentMedia._id
        );
      } else {
        return -1;
      }
    },
    hasNavigation() {
      return {
        previous: this.currentMediaIndex > 0,
        next: this.currentMediaIndex < this.medias.length - 1,
      };
    },
  },
  methods: {
    cancelSelectMedia() {
      this.isSelectingMedia = false;

      let medias = JSON.parse(JSON.stringify(this.medias));

      medias = medias.map((m) => {
        m.selected = false;

        return m;
      });

      this.medias = medias;
    },
    selectMedia(media) {
      if (this.isSelectingMedia) {
        let selectedMediaIndex = this.medias.findIndex(
          (m) => m._id == media._id
        );

        if (selectedMediaIndex > -1) {
          let medias = JSON.parse(JSON.stringify(this.medias));

          let selectedMedia = medias[selectedMediaIndex];

          selectedMedia.selected = selectedMedia.selected ? false : true;

          this.medias = medias;
        }
      } else {
        this.currentMedia = media;
      }
    },
    async editMedia() {
      const { value: mediaCaption } = await Swal.fire({
        title: "<h5 class='mb-0'>Update media caption</h5>",
        input: "text",
        inputLabel: "",
        inputValue: "",
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          input: "text-light",
        },
      });

      if (mediaCaption && this.isSelectingMedia) {
        this.$emit("toggle-spinner", true);

        const selectedMedias = this.medias.filter((m) => m.selected);

        if (selectedMedias.length > 0) {
          await Promise.all(
            selectedMedias.map((sm) => this.editMediaCaption(sm, mediaCaption))
          );
        }

        this.cancelSelectMedia();

        this.$emit("toggle-spinner", false);

        Swal.fire("<h5 class='mb-0'>Image caption updated</h5>", "", "success");
      }
    },
    async editMediaCaption(media, caption) {
      if (media && media.captionId) {
        const [call, err] = await this.Helper.handle(
          this.API.put(`site-gallery-captions/${media.captionId}`, {
            text: caption,
          })
        );

        if (!err && call.status == 200) {
          this.updateMediaCaption(media, call.data);
        }
      } else {
        const [call, err] = await this.Helper.handle(
          this.API.post("site-gallery-captions", {
            site: this.gallery.site._id,
            text: caption,
            file: media._id,
          })
        );

        if (!err && call.status == 200) {
          this.updateMediaCaption(media, call.data);
        }
      }
    },
    updateMediaCaption(media, caption) {
      const mediaObject = this.medias.find((m) => m._id == media._id);

      if (mediaObject) {
        mediaObject.caption = caption.text;
        mediaObject.captionId = caption._id;
      }
    },
    removeMedia(media) {
      Swal.fire({
        title: `<h5 class='mb-0'>Delete ${
          this.isSelectingMedia ? "selected" : "this"
        } image?</h5>`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          if (this.isSelectingMedia) {
            const selectedMedias = this.medias.filter((m) => m.selected);

            if (selectedMedias.length > 0) {
              await Promise.all(
                selectedMedias.map((sm) => this.deleteMedia(sm))
              );
            }
          } else if (media) {
            await this.deleteMedia(media);
          }

          this.cancelSelectMedia();

          this.$emit("toggle-spinner", false);

          Swal.fire("<h5 class='mb-0'>Image deleted</h5>", "", "success");
        }
      });
    },
    async deleteMedia(media) {
      const [call, err] = await this.Helper.handle(
        this.API.del(`upload/files/${media._id}`)
      );

      if (!err && call.status == 200) {
        this.medias = this.medias.filter((m) => m._id != media._id);
      }
    },
    async closeMediaUploader(e) {
      this.showMediaUploader = false;

      if (e && e == "update") {
        await this.getSiteGalleries();
      }
    },
    navigate(direction) {
      if (direction == "previous" && this.hasNavigation.previous) {
        this.currentMedia = this.medias[this.currentMediaIndex - 1];
      } else if (direction == "next" && this.hasNavigation.next) {
        this.currentMedia = this.medias[this.currentMediaIndex + 1];
      }
    },
    async getSiteGalleries() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(
          `site-galleries?site=${this.$route.params.id}&name=Gallery Images&_sort=createdAt:DESC`
        )
      );

      if (!err && call.status == 200) {
        if (call.data && call.data.length > 0) {
          this.gallery = JSON.parse(JSON.stringify(call.data[0]));

          delete this.gallery.media;

          this.medias = call.data[0] ? call.data[0].media : [];
        }
      }

      this.getMediaCaptions();
    },
    async getMediaCaptions() {
      const [call, err] = await this.Helper.handle(
        this.API.get(
          `site-gallery-captions?site=${this.$route.params.id}&_limit=-1`
        )
      );

      if (!err && call.status == 200) {
        this.medias = this.medias.map((media) => {
          const cap = call.data.find(
            (caption) => caption.file && caption.file._id == media._id
          );

          if (cap) {
            media.caption = cap.text;
            media.captionId = cap._id;
          }

          return media;
        });
      }

      this.$emit("toggle-spinner", false);
    },
  },
  mounted() {
    this.getSiteGalleries();
  },
};
</script>