<template>
  <div class="mapbox-popup-container">
    <p
      class="
        popup-header
        line-height-sm
        border-0
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <span>
        {{ data.name }}<br />
        <small class="text-muted">{{ data.date }}</small>
      </span>
      <button
        class="btn btn-danger btn-sm"
        @click="deletePolygon(data._id)"
        v-if="!nonInteractive"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </p>
  </div>
</template>

<script>
export default {
  props: ["data", "nonInteractive"],
  mounted() {
    if (Math.ceil(this.$el.clientWidth) % 2) {
      this.$el.style.width = Math.ceil(this.$el.clientWidth) + 1 + "px";
    }
  },
};
</script>