<template>
  <div class="media-preview">
    <div class="media-header">
      <h1 class="d-flex justify-content-start align-items-center" v-if="report">
        {{ report.site.name }} Report
      </h1>
      <button class="btn border shadow-sm btn-close" @click="closePreview()">
        &times;
      </button>
    </div>
    <div class="media-container overflow-auto">
      <div v-if="report" class="w-100">
        <div class="bg-light p-3">
          <p class="lead font-weight-bold text-dark">Report Details</p>

          <table class="table table-bordered mb-0">
            <tr>
              <th>Report Name</th>
              <td colspan="5">{{ report.name }}</td>
            </tr>
            <tr>
              <th>Reported By</th>
              <td colspan="3">
                {{ report.user ? report.user.username : "" }}
              </td>
              <th>Date</th>
              <td width="20%" class="text-nowrap">
                {{ Helper.formatDate(report.date, true) }}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td style="width: 20%">{{ report.status }}</td>
              <th>Scope</th>
              <td style="width: 20%">{{ report.scope }}</td>
              <th>Severity</th>
              <td style="width: 20%">{{ report.severity }}</td>
            </tr>
            <tr>
              <th>Issue</th>
              <td colspan="5">
                <div class="large-cell">{{ report.issue }}</div>
              </td>
            </tr>
            <tr>
              <th>Action</th>
              <td colspan="5">
                <div class="large-cell">{{ report.action }}</div>
              </td>
            </tr>
            <tr>
              <th>Remarks</th>
              <td colspan="5">
                <div class="large-cell">{{ report.remark }}</div>
              </td>
            </tr>
          </table>
        </div>

        <div class="report-images p-3 bg-light mt-3" v-if="report.media.length">
          <p class="lead font-weight-bold text-dark">Report Images</p>

          <img
            class="img-fluid"
            :src="Helper.formatMediaUrl(media)"
            v-for="(media, index) in report.media"
            :class="{ 'mt-3': index !== 0 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "report-viewer",
  props: ["report"],
  data() {
    return {};
  },
  methods: {
    closePreview() {
      this.$nextTick(() => {
        this.$emit("close");
      });
    },
  },
  mounted() {
    $("body").addClass("overflow-hidden");
  },
  beforeDestroy() {
    $("body").removeClass("overflow-hidden");

    this.closePreview();
  },
};
</script>

<style lang="scss" scoped>
th {
  white-space: nowrap !important;
  width: 1%;
}

.large-cell {
  min-height: 100px;
}
</style>