<template>
  <div class="floating-form" :class="{ show: show }">
    <div
      class="
        p-3
        h5
        mb-0
        border-bottom border-default-dark
        bg-dark
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <small class="font-weight-medium text-default-dark">Annotation</small>
      <button class="btn p-0 text-muted" @click="close()">
        <i class="fas fa-times" />
      </button>
    </div>
    <div class="bg-default-dark p-3">
      <p class="small text-muted mb-1">Click on map to start drawing</p>
      <div class="form-group">
        <label for="polygonName">Name<sup class="text-danger">*</sup></label>
        <input
          id="polygonName"
          type="text"
          class="form-control"
          v-model="models.name"
        />
      </div>
      <div class="form-group clickable" @click="showCalendarModal = true">
        <label for="layerDate">Date</label>
        <div class="form-control">
          <i class="fas fa-calendar-alt mr-2" />
          {{ models.date }}
        </div>
      </div>
    </div>
    <button class="btn btn-primary btn-block py-2" @click="save()">Save</button>
    <date-picker-modal
      v-if="showCalendarModal"
      :value="models.date"
      @close="closeCalendarModal"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePickerModal from "@/components/DatePickerModal";

export default {
  props: ["show", "features"],
  components: {
    DatePickerModal,
  },
  data() {
    return {
      models: {
        name: null,
        date: null,
      },
      emptyModels: {
        name: null,
        date: null,
      },
      errors: [],
      showCalendarModal: false,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show && this.$el) {
          this.$el.scrollTo(0, 0);
        }

        this.models = JSON.parse(JSON.stringify(this.emptyModels));
      },
      immediate: true,
    },
  },
  methods: {
    closeCalendarModal(e) {
      this.showCalendarModal = false;

      if (e !== "close") {
        this.models.date = e;
      }
    },
    async save() {
      this.errors = [];

      if (!this.models.name) {
        this.errors.push("Name is required");
      }

      if (!this.features) {
        this.errors.push("No area selected");
      }

      if (this.errors.length > 0) {
        Swal.fire({
          title: "<h5>Error</h5>",
          html: `<div class='line-height-md'>${this.errors.join(
            "<br />"
          )}</div>`,
          icon: "error",
        });
      } else {
        this.$emit("toggle-spinner", true);

        let body = JSON.parse(JSON.stringify(this.models));

        body.name = body.name.trim();
        body.site = this.$route.params.id;
        body.features = this.features;

        const [call, err] = await this.Helper.handle(
          this.API.post("site-polygons", body)
        );

        if (!err && call.status == 200) {
          this.$emit("toggle-spinner", false);

          Swal.fire(
            "<h5 class='mb-0'>Annotation added</h5>",
            "",
            "success"
          ).then(() => {
            this.$emit("close", call.data);
          });
        } else if (err) {
          this.$emit("toggle-spinner", false);

          Swal.fire("<h5 class='mb-0'>Error</h5>", err.message, "error");
        }
      }
    },
    close(e) {
      this.$emit("close", e);
    },
  },
};
</script>