<template>
  <div class="h-100 pb-3 overflow-auto container-fluid position-relative">
    <button class="btn btn-fab btn-primary shadow" @click="showReportForm()">
      <i class="fas fa-plus" />
    </button>
    <div class="table-filters bg-default-dark shadow-bottom">
      <div class="input-group flex-nowrap">
        <input
          type="text"
          class="form-control h-100 flex-fill"
          placeholder="Type here to search"
          style="padding-top: 0.75rem; padding-bottom: 0.75rem"
          v-model="search.text"
        />
        <div class="input-group-append">
          <button
            class="btn btn-dark dropdown-toggle text-default-dark border-text-default-dark d-flex justify-content-between align-items-center"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            style="width: 90px"
          >
            <div class="text-left line-height-md">
              <p class="text-muted small mb-0">Search by</p>
              <p class="mb-0">{{ search.type }}</p>
            </div>
          </button>
          <div class="dropdown-menu border-dark shadow">
            <h6 class="dropdown-header px-3">Search by</h6>
            <button
              class="dropdown-item px-3 d-flex justify-content-between align-items-center"
              v-for="type in options.searchType"
              :key="`searchType-${type}`"
              @click="search.type = type"
            >
              {{ type }}
              <i class="fas fa-check ml-3" v-if="search.type == type" />
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="dropdown filter-item">
          <button
            class="btn btn-dark text-default-dark border-text-default-dark dropdown-toggle d-flex justify-content-between align-items-center h-100"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="text-left line-height-md">
              <p class="text-muted small mb-0">Severity</p>
              <p class="mb-0">{{ filter.severity || "All" }}</p>
            </div>
          </button>
          <div class="dropdown-menu border-dark shadow">
            <h6 class="dropdown-header px-3">Filter severity</h6>
            <button
              class="dropdown-item px-3 d-flex justify-content-between align-items-center"
              type="button"
              v-for="(severity, index) in options.severity"
              :key="`filterSeverity-${index}`"
              @click="filter.severity = severity"
            >
              {{ severity || "All" }}
              <i class="fas fa-check ml-3" v-if="filter.severity == severity" />
            </button>
          </div>
        </div>
        <div class="dropdown filter-item">
          <button
            class="btn btn btn-dark text-default-dark border-text-default-dark dropdown-toggle d-flex justify-content-between align-items-center h-100"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="text-left line-height-md">
              <p class="text-muted small mb-0">Status</p>
              <p class="mb-0">{{ filter.status || "All" }}</p>
            </div>
          </button>
          <div class="dropdown-menu border-dark shadow">
            <h6 class="dropdown-header px-3">Filter status</h6>
            <button
              class="dropdown-item px-3 d-flex justify-content-between align-items-center"
              type="button"
              v-for="(status, index) in options.status"
              :key="`filterStatus-${index}`"
              @click="filter.status = status"
            >
              {{ status || "All" }}
              <i class="fas fa-check ml-3" v-if="filter.status == status" />
            </button>
          </div>
        </div>
        <date-picker-dropdown
          class="flex-shrink-0 filter-item"
          btn-class="
            btn-dark
            text-default-dark
            border-text-default-dark
            h-100
            text-left
            line-height-md
            d-flex
            justify-content-between
            align-items-center
            w-100
          "
          :close-on-clear="true"
          :no-icon="true"
          title="Date"
          null-text="All"
          :btn-style="{ minWidth: '120px' }"
          :value="filter.date"
          @date-returned="filter.date = $event"
        />
      </div>
      <div class="d-flex flex-row">
        <button
          class="btn btn-link px-4 text-danger text-nowrap font-weight-bold filter-action"
          :class="{ invisible: filterDirty }"
          @click="clearFilters()"
        >
          CLEAR
        </button>
      </div>
    </div>
    <div
      class="table-responsive bg-dark rounded mt-2"
      v-if="computedReports.length > 0"
    >
      <table class="table table-borderless table-striped mb-0">
        <thead class="thead-dark">
          <tr class="text-default">
            <th class="w-1">No</th>
            <th class="text-nowrap">Title</th>
            <th class="text-nowrap">Who &amp; When</th>
            <th>Scope</th>
            <th>Issue</th>
            <th>Severity</th>
            <th>Action</th>
            <th>Status</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-default-dark"
            v-for="(report, index) in computedReports"
            :key="report._id"
          >
            <td class="w-1">{{ index + 1 }}</td>
            <td>{{ report.name }}</td>
            <td>
              {{ report.user ? report.user.username : "" }}<br />
              {{ Helper.formatDate(report.date, true) }}
            </td>
            <td>{{ report.scope }}</td>
            <td>
              {{ report.issue }}
              <div
                class="line-height-sm py-1 px-2 bg-default-dark mt-2 rounded"
                v-if="report.remarks"
              >
                <small>Remarks</small><br />
                <small class="text-muted">{{ report.remarks }}</small>
              </div>
            </td>
            <td>{{ report.severity }}</td>
            <td>
              {{ report.action }}
            </td>
            <td>{{ report.status }}</td>
            <td class="text-center text-nowrap w-1">
              <button
                class="btn btn-sm btn-info mx-1"
                @click="viewingReport = report"
              >
                <i class="fas fa-eye" />
              </button>
              <button
                class="btn btn-sm btn-primary mx-1"
                @click="showReportForm(report)"
              >
                <i class="fas fa-edit" />
              </button>
              <button
                class="btn btn-sm btn-danger mx-1"
                @click="deleteReport(report._id)"
              >
                <i class="fas fa-trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bg-dark p-2 rounded" v-else>
      <p class="text-center font-italic mb-0 py-3">No report found</p>
    </div>
    <report-form-modal
      v-if="reportForm.show"
      :report="reportForm.currentReport"
      :site="computedReportSite"
      @toggle-spinner="$emit('toggle-spinner', $event)"
      @close="closeReportForm"
      @close-edit="closeReportForm($event, 'edit')"
    />

    <report-viewer
      v-if="viewingReport"
      :report="viewingReport"
      @close="viewingReport = null"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DatePickerDropdown from "@/components/DatePickerDropdown";
import ReportFormModal from "@/components/ReportFormModal";
import ReportViewer from "@/components/ReportViewer";

export default {
  name: "component-site-report",
  props: ["data"],
  components: {
    DatePickerDropdown,
    ReportFormModal,
    ReportViewer,
  },
  data() {
    return {
      viewingReport: null,
      reports: [],
      reportForm: {
        show: false,
        currentReport: null,
      },
      search: {
        text: "",
        type: "Title",
      },
      filter: {
        severity: null,
        date: null,
        status: null,
      },
      options: {
        searchType: ["Title", "Scope", "Issue"],
        severity: [null, "Low", "Normal", "Medium", "High"],
        status: [null, "New"],
      },
    };
  },
  computed: {
    filterDirty() {
      return (
        Object.values(this.filter).every((f) => !f) &&
        this.search.text.length < 1
      );
    },
    computedReportSite() {
      const result = {
        _id: this.data ? this.data._id : null,
        name: this.data ? this.data.name : null,
        lat: this.data ? this.data.lat : null,
        lng: this.data ? this.data.lng : null,
        tiles:
          this.data && this.data.tiles && this.data.tiles.length > 0
            ? this.data.tiles
            : [],
      };

      return result;
    },
    computedReports() {
      let result = JSON.parse(JSON.stringify(this.reports));

      if (this.filter.severity) {
        result = result.filter((r) => r.severity == this.filter.severity);
      }

      if (this.filter.status) {
        result = result.filter((r) => r.status == this.filter.status);
      }

      if (this.filter.date) {
        result = result.filter(
          (r) =>
            this.Helper.formatDate(r.date) ==
            this.Helper.formatDate(this.filter.date)
        );
      }

      if (this.search.text) {
        let searchType =
          this.search.type == "Title" ? "Name" : this.search.type;

        result = result.filter((r) =>
          r[searchType.toLowerCase()]
            .toLowerCase()
            .includes(this.search.text.toLowerCase())
        );
      }

      return result;
    },
  },
  methods: {
    deleteReport(reportId) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this report?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          const [call, err] = await this.Helper.handle(
            this.API.del(`reports/${reportId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire(
              "<h5 class='mb-0'>Report deleted</h5>",
              "",
              "success"
            ).then(() => {
              this.reports = this.reports.filter(
                (report) => report._id !== reportId
              );

              this.$emit("toggle-spinner", false);
            });
          }
        }
      });
    },
    showReportForm(e) {
      this.reportForm = {
        show: true,
        currentReport: e,
      };
    },
    closeReportForm(e, type) {
      this.reportForm = {
        show: false,
        currentReport: null,
      };

      if (e) {
        if (type == "edit") {
          const reportIndex = this.reports.findIndex(
            (report) => report._id == e._id
          );

          if (reportIndex >= 0) {
            this.$set(this.reports, reportIndex, e);
          }
        } else {
          this.reports.unshift(e);
        }
      }
    },
    clearFilters() {
      this.search = {
        text: "",
        type: "Title",
      };

      this.filter = {
        severity: null,
        date: null,
        status: null,
      };
    },
    async getReports() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(
          `reports?site=${this.$route.params.id}&_sort=createdAt:DESC`
        )
      );

      if (!err && call.status == 200) {
        this.reports = call.data;
      }

      this.$emit("toggle-spinner", false);
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>