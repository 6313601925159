<template>
  <div id="fileUploadModal" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog" style="transition: all 0.3s">
      <div class="modal-content bg-default-dark border-text-default-dark">
        <div class="modal-header align-items-center">
          <h5 class="modal-title mb-0 text-nowrap">Upload File</h5>
          <div>
            <select v-model="selectedType" class="custom-select custom-select-sm">
              <option v-for="type in types" :value="type">{{ typeLabel(type).toUpperCase() }}</option>
            </select>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="formData.file" class="table-responsive">
            <div class="badge badge-light rounded-pill px-3 py-2 mb-3">
              (<sup class="text-danger">*</sup>) is required field
            </div>
            <table class="table table-bordered mb-0">
              <thead class="table-dark">
                <tr>
                  <th>
                    File Name
                    <sup class="text-danger">*</sup>
                  </th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input class="form-control form-control-sm" v-model="formData.name" type="text">
                  </td>
                  <td class="text-center w-1">
                    <button class="btn btn-danger btn-sm text-nowrap" @click="clearFile()">
                      <i class="fas fa-times mr-1" />
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <file-drop note="Accepted format: .pdf" :accept="fileAcceptedFormats" @selected="fileSelected" v-else />
        </div>

        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary text-light" data-bs-dismiss="modal" @click="closeModal()">
            Cancel
          </button>
          <div v-if="formData.file">
            <button class="btn btn-primary text-uppercase" @click="handleSubmit" :disabled="formInvalid">
              <i class="fas fa-exclamation-triangle text-danger mr-2" v-if="formInvalid" />
              Upload {{ typeLabel(selectedType) }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <spinner :show="isSaving" :text="`${isSavingText || 'Uploading file...'}`" />
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import FileDrop from "@/components/FileDrop";
import Spinner from "@/components/Spinner";

export default {
  name: "modal-file-upload",
  components: { Spinner, FileDrop },
  props: ['siteId', 'type'],
  data() {
    return {
      types: ['permit_OR_license_approval_file', 'luas_approval_file', 'oms_file', 'eia_file', 'site_report_file'],
      selectedType: null,
      formData: {
        name: "",
        file: null,
      },
      emptyFormData: {
        name: "",
        file: null,
      },
      requiredData: ["name", "file"],
      isSaving: false,
      isSavingText: null,
      fileAcceptedFormats: ["application/pdf"],
    };
  },
  watch: {
    type: {
      handler() {
        this.selectedType = this.type;
      }
    }
  },
  computed: {
    formInvalid() {
      let value = false;

      this.requiredData.forEach(key => {
        if (!this.formData[key]) value = true;
      });

      return value;
    },
  },
  methods: {
    typeLabel(type) {
      if (!type) return;

      return this.Helper.splitUnderscore(type.replace("_file", ""));
    },
    fileSelected(e) {
      this.formData.file = e;
      this.formData.name = e.name;
    },
    async handleSubmit() {
      if (!this.formInvalid) {
        this.isSaving = true;

        this.isSavingText = "Uploading file";

        let result = null;

        try {
          const fd = new FormData();

          let url = "upload";

          fd.append("files", this.formData.file, this.formData.name);
          fd.append("ref", "site");
          fd.append("refId", this.siteId);
          fd.append("field", this.selectedType);

          const [call, err] = await this.Helper.handle(
            this.API.postForm(url, fd)
          );

          result = call.data[0];
        } catch (error) {
          this.isSavingText = "Error";

          Swal.fire({
            title: "<h5>Error</h5>",
            html: `<div class='line-height-md text-light'>${error}</div>`,
            icon: "error",
          });
        }

        if (this.isSavingText !== "Error") {
          Swal.fire({
            title: `<h5 class='text-uppercase'>${this.typeLabel(this.selectedType)} uploaded</h5>`,
            icon: "success",
          }).then(() => {
            this.closeModal(true, result);
          });
        }

        this.isSaving = false;

        this.isSavingText = null;
      }
    },
    closeModal(reload, file) {
      this.$emit("close");

      if (reload) {
        this.$emit("reload", { key: this.selectedType, file: file });
      }
    },
    clearFile() {
      this.$set(this, 'formData', { ...this.emptyFormData });
    },
  },
  mounted() {
    const vm = this;

    $("#fileUploadModal").on("hidden.bs.modal", function (event) {
      vm.clearFile();
    });
  },
  beforeDestroy() {
    $("fileUploadModal").off("hidden.bs.modal");
  },
};
</script>