<template>
  <div class="position-relative w-100 h-100">
    <div id="site-map" class="w-100 h-100"></div>
    <div
      id="map-measurement-text"
      class="text-monospace"
      v-if="showTools.measurement"
    >
      <p>Total distance: {{ measurement }} km</p>
    </div>
    <div class="map-floating-button">
      <template v-if="showMapButton">
        <div class="d-flex justify-content-end align-items-center">
          <button
            class="btn shadow mr-2"
            :class="
              showTools.layer ? 'btn-success' : 'btn-dark text-default-dark'
            "
            data-toggle="tooltip"
            title="Toggle layer tool"
            :disabled="disableToolButton('layer')"
            @mouseenter="Helper.showTooltip($event)"
            @click="showTools.layer = !showTools.layer"
          >
            <i class="fas fa-layer-group"></i>
          </button>
          <button
            class="btn shadow mr-2"
            :class="
              showTools.standoff || showTools.polygon
                ? 'btn-success'
                : 'btn-dark text-default-dark'
            "
            data-toggle="tooltip"
            title="Toggle annotation tool"
            :disabled="disableToolButton('annotation')"
            @mouseenter="Helper.showTooltip($event)"
            @click="triggerAnnotationPrompt()"
          >
            <i class="fas fa-draw-polygon"></i>
          </button>
          <button
            class="btn shadow mr-2"
            :class="
              showTools.measurement
                ? 'btn-success'
                : 'btn-dark text-default-dark'
            "
            data-toggle="tooltip"
            title="Toggle measurement tool"
            :disabled="disableToolButton('measurement')"
            @mouseenter="Helper.showTooltip($event)"
            @click="showTools.measurement = !showTools.measurement"
          >
            <i class="fas fa-ruler-combined"></i>
          </button>
          <button
            class="btn shadow"
            :class="
              isSatelliteView ? 'btn-success' : 'btn-dark text-default-dark'
            "
            data-toggle="tooltip"
            title="Toggle satellite view"
            @mouseenter="Helper.showTooltip($event)"
            @click="isSatelliteView = !isSatelliteView"
          >
            <i class="fas fa-satellite"></i>
          </button>
        </div>
        <div class="dropdown mt-2">
          <button
            class="btn btn-dark text-default-dark shadow dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
            type="button"
            id="dropdownLayer"
            data-toggle="dropdown"
          >
            Layers
          </button>
          <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
            <div
              @click.stop
              class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="tileVisibilityToggler"
                  v-model="showAllTile"
                  @change="toggleTileVisibility('all')"
                  v-if="mapLayers.length > 0"
                />
                <label
                  class="custom-control-label text-nowrap text-default-dark"
                  for="tileVisibilityToggler"
                >
                  2D Ortho
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text"
              @click.stop
              v-for="layer in mapLayers.toSorted((a, b) => {
                const aDate = a && a.date ? moment(a.date).valueOf() : 0;
                const bDate = b && b.date ? moment(b.date).valueOf() : 0;

                return bDate - aDate;
              })"
              :key="`layer-${layer.id}`"
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="layer.id"
                  v-model="layer.show"
                  @change="toggleTileVisibility(layer.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="layer.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ layer.name }}
                    <button
                      class="btn btn-sm text-info p-0 mb-1 ml-2"
                      :class="{
                        invisible:
                          showTools.layer || disableToolButton('layer'),
                      }"
                      @click="selectLayer(layer)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <template v-if="layer.date">
                      <br /><small class="text-muted">{{
                        Helper.formatDate(layer.date, "YYYY-MM")
                      }}</small>
                    </template>
                  </span>
                  <span v-if="layer.show">{{ layer.opacity }}%</span>
                </label>
              </div>
              <input
                type="range"
                class="custom-range"
                :id="`slider-${layer.id}`"
                step="5"
                v-model="layer.opacity"
                @input="adjustTileVisibility(layer.id)"
                :disabled="!layer.show"
              />
            </div>
            <div
              class="dropdown-item-text text-muted text-center small"
              @click.stop
              v-if="mapLayers.length < 1"
            >
              No tile layer found
            </div>
            <div class="dropdown-item-text p-1 bg-dark shadow-inset"></div>
            <div
              @click.stop
              class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="geojsonVisibilityToggler"
                  v-model="showAllGeojson"
                  @change="toggleGeojsonVisibility('all')"
                  v-if="geojsons.length > 0"
                />
                <label
                  class="custom-control-label text-nowrap text-default-dark"
                  for="geojsonVisibilityToggler"
                >
                  GIS Layers
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text text-muted"
              @click.stop
              v-for="geojson in geojsons"
              :key="`geojson-${geojson.id}`"
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="geojson.id"
                  v-model="geojson.show"
                  @change="toggleGeojsonVisibility(geojson.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="geojson.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ Helper.formatGeojsonName(geojson.name) }}
                    <i
                      class="fas fa-circle ml-1"
                      :style="{ color: geojson.lineColor, fontSize: '70%' }"
                    />
                  </span>
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text text-muted text-center small"
              @click.stop
              v-if="geojsons.length < 1"
            >
              No GIS layer found
            </div>
          </div>
        </div>
        <div class="dropdown mt-2">
          <button
            class="btn btn-dark text-default-dark shadow dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
            type="button"
            id="dropdownAnnotation"
            data-toggle="dropdown"
          >
            Annotations
          </button>
          <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
            <div
              @click.stop
              class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="polygonVisibilityToggler"
                  v-model="showAllPolygon"
                  @change="togglePolygonVisibility('all')"
                  v-if="polygons.length > 0"
                />
                <label
                  class="custom-control-label text-nowrap text-default-dark"
                  for="polygonVisibilityToggler"
                >
                  Show All
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text"
              @click.stop
              v-for="polygon in polygons"
              :key="`polygon-${polygon.id}`"
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="polygon.id"
                  v-model="polygon.show"
                  @change="togglePolygonVisibility(polygon.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="polygon.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ polygon.name }}<br />
                    <small>{{ polygon.date }}</small>
                  </span>
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text text-muted text-center small"
              @click.stop
              v-if="polygons.length < 1"
            >
              No annotation found
            </div>
          </div>
        </div>
        <div class="dropdown mt-2">
          <button
            class="btn btn-dark text-default-dark shadow dropdown-toggle d-flex justify-content-between align-items-center ml-auto w-100"
            type="button"
            id="dropdownStandoff"
            data-toggle="dropdown"
          >
            Standoff / Drone
          </button>
          <div class="dropdown-layer-menu dropdown-menu dropdown-menu-right">
            <div
              @click.stop
              class="dropdown-item-text text-muted font-weight-medium shadow-inset text-uppercase py-2"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="standoffVisibilityToggler"
                  v-model="showAllStandoff"
                  @change="toggleStandoffVisibility('all')"
                  v-if="standoffShots.length > 0"
                />
                <label
                  class="custom-control-label text-nowrap text-default-dark"
                  for="standoffVisibilityToggler"
                >
                  Show All
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text"
              @click.stop
              v-for="standoff in standoffShots"
              :key="`standoff-${standoff.id}`"
            >
              <div class="custom-control custom-checkbox py-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="standoff.id"
                  v-model="standoff.show"
                  @change="toggleStandoffVisibility(standoff.id)"
                />
                <label
                  class="custom-control-label text-muted text-nowrap"
                  :for="standoff.id"
                >
                  <span class="mr-4 line-height-sm">
                    {{ standoff.name }}<br />
                    <small>{{ standoff.date }}</small>
                  </span>
                </label>
              </div>
            </div>
            <div
              class="dropdown-item-text text-muted text-center small"
              @click.stop
              v-if="standoffShots.length < 1"
            >
              No shot found
            </div>
          </div>
        </div>
      </template>
      <div
        class="bg-dark py-3 px-4 rounded d-flex justify-content-center align-items-center"
        v-else
      >
        <div class="spinner-border spinner-border-sm mr-3" role="status"></div>
        Loading layers...
      </div>
    </div>
    <standoff-form
      :show="showTools.standoff"
      :mouseCoordinate="mouseClickCoordinate"
      @close="closeAnnotationTool"
      @toggle-spinner="toggleSpinner"
      @rotate="rotateStandoff"
    />
    <polygon-form
      :show="showTools.polygon"
      :features="polygonFeatures"
      @close="closeAnnotationTool"
      @toggle-spinner="toggleSpinner"
    />
    <layer-form
      :show="showTools.layer"
      :site="data"
      :layer="currentLayer"
      @close="closeLayerTool"
      @toggle-spinner="toggleSpinner"
    />
    <media-preview
      v-if="currentMedia"
      :title="
        currentMedia.title ? currentMedia.title : `${data.name} Standoff Shot`
      "
      :subtitle="currentMedia.subtitle ? currentMedia.subtitle : null"
      :media="currentMedia.data ? currentMedia.data : currentMedia"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import Moment from "moment";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import Swal from "sweetalert2";
import MediaPreview from "@/components/MediaPreview";
import StandoffForm from "@/components/StandoffForm";
import StandoffPopup from "@/components/StandoffPopup";
import PolygonForm from "@/components/PolygonForm";
import PolygonPopup from "@/components/PolygonPopup";
import LayerForm from "@/components/LayerForm";
import Vue from "vue";

export default {
  name: "component-site-orthomosaic",
  props: ["data"],
  components: {
    StandoffForm,
    PolygonForm,
    LayerForm,
    MediaPreview,
  },
  data() {
    return {
      map: null,
      moment: Moment,
      measurement: 0,
      showMapButton: false,
      showTools: {
        measurement: false,
        standoff: false,
        polygon: false,
        layer: false,
      },
      isSatelliteView: false,
      measurementGeoJson: {
        type: "FeatureCollection",
        features: [],
      },
      measurementLineString: {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      },
      mapLayers: [],
      currentLayer: null,
      geojsons: [],
      showAllGeojson: false,
      showAllTile: true,
      currentMedia: null,
      mouseClickCoordinate: {},
      standoffShots: [],
      showAllStandoff: true,
      standoffMarker: null,
      polygonDrawing: null,
      polygonFeatures: null,
      polygons: [],
      showAllPolygon: true,
    };
  },
  computed: {
    disableToolButton() {
      return (type) => {
        let tools = JSON.parse(JSON.stringify(this.showTools));

        delete tools[type];

        return Object.values(tools).some((tool) => tool);
      };
    },
  },
  watch: {
    "showTools.measurement": {
      handler() {
        if (!this.showTools.measurement) {
          this.removeMeasurement();
        } else {
          this.drawMeasurement();
        }
      },
      immediate: true,
    },
    isSatelliteView: {
      handler() {
        this.showTools.measurement = false;

        if (this.map) {
          let styleUrl = this.Helper.mapStyle(this.isSatelliteView);

          this.map.setStyle(styleUrl);
        }
      },
      immediate: false,
    },
    data: {
      handler() {
        if (this.data) {
          this.getStandoffShots();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    selectLayer(layer) {
      this.currentLayer = layer;

      this.showTools.layer = true;
    },
    rotateStandoff(e) {
      if (this.standoffMarker) {
        this.standoffMarker.setRotation(e);
      }
    },
    addPolygonToMap(polygon) {
      this.map.addSource(`polygonSource-${polygon._id}`, {
        type: "geojson",
        data: polygon.features,
      });

      this.map.addLayer({
        id: `polygonLayerFill-${polygon._id}`,
        type: "fill",
        source: `polygonSource-${polygon._id}`,
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });

      this.map.addLayer({
        id: `polygonLayerOutline-${polygon._id}`,
        type: "line",
        source: `polygonSource-${polygon._id}`,
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });

      this.map.on("click", `polygonLayerFill-${polygon._id}`, (e) => {
        this.showPolygonPopup(e, polygon);
      });

      this.map.on("mouseenter", `polygonLayerFill-${polygon._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon
        ) {
          this.map.getCanvas().style.cursor = "pointer";
        }
      });

      this.map.on("mouseleave", `polygonLayerFill-${polygon._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon
        ) {
          this.map.getCanvas().style.cursor = "";
        }
      });
    },
    showPolygonPopup(e, polygon) {
      const PolygonPopupClass = Vue.extend(PolygonPopup);

      const popup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(`<div id="polygon-popup-content-${polygon._id}"></div>`)
        .addTo(this.map);

      const vm = this;

      const popupInstance = new PolygonPopupClass({
        propsData: {
          data: polygon,
        },
        methods: {
          deletePolygon(e) {
            if (e) {
              vm.deletePolygon(e, popup);
            }
          },
        },
      });

      popupInstance.$mount(`#polygon-popup-content-${polygon._id}`);

      popup._update();
    },
    deletePolygon(polygonId, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this annotation?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          popup.remove();

          const [call, err] = await this.Helper.handle(
            this.API.del(`site-polygons/${polygonId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire(
              "<h5 class='mb-0'>Annotation deleted</h5>",
              "",
              "success"
            ).then(() => {
              const layers = this.map
                .getStyle()
                .layers.filter(
                  (layer) =>
                    layer.id == `polygonLayerFill-${polygonId}` ||
                    layer.id == `polygonLayerOutline-${polygonId}`
                );

              const sources = Object.keys(this.map.getStyle().sources).filter(
                (source) => source == `polygonSource-${polygonId}`
              );

              if (layers && layers.length > 0) {
                layers.forEach((layer) => {
                  this.map.removeLayer(layer.id);
                });
              }

              if (sources && sources.length > 0) {
                sources.forEach((source) => {
                  this.map.removeSource(source);
                });
              }

              this.polygons = this.polygons.filter(
                (polygon) => polygon.id !== `polygonLayer:type-${polygonId}`
              );

              this.$emit("toggle-spinner", false);
            });
          }
        }
      });
    },
    addStandoffShotToMap(shot) {
      this.map.addSource(`standoffSource-${shot._id}`, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [shot.lng, shot.lat],
              },
            },
          ],
        },
      });

      this.map.addLayer({
        id: `standoffLayer-${shot._id}`,
        type: "symbol",
        source: `standoffSource-${shot._id}`,
        layout: {
          "icon-image": "drone",
          "icon-size": 1,
          "icon-rotate": shot.bearing || 0,
          "icon-allow-overlap": true,
        },
      });

      this.map.on("click", `standoffLayer-${shot._id}`, (e) => {
        if (!this.showTools.standoff) {
          const StandoffPopupClass = Vue.extend(StandoffPopup);

          const popup = new mapboxgl.Popup()
            .setLngLat([shot.lng, shot.lat])
            .setHTML(`<div id="standoff-popup-content-${shot._id}"></div>`)
            .addTo(this.map);

          const vm = this;

          const popupInstance = new StandoffPopupClass({
            propsData: {
              data: shot,
            },
            methods: {
              viewImage(e) {
                if (e) {
                  vm.currentMedia = {
                    title: shot.name,
                    subtitle: shot.date,
                    data: e,
                  };
                }
              },
              deleteShot(e) {
                if (e) {
                  vm.deleteStandoffShot(e, popup);
                }
              },
              cloneShot(lat, lng, bearing) {
                vm.cloneStandoffShot(lat, lng, bearing, popup);
              },
            },
          });

          popupInstance.$mount(`#standoff-popup-content-${shot._id}`);

          popup._update();
        }
      });

      this.map.on("mouseenter", `standoffLayer-${shot._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon
        ) {
          this.map.getCanvas().style.cursor = "pointer";
        }
      });

      this.map.on("mouseleave", `standoffLayer-${shot._id}`, () => {
        if (
          !this.showTools.measurement &&
          !this.showTools.standoff &&
          !this.showTools.polygon
        ) {
          this.map.getCanvas().style.cursor = "";
        }
      });
    },
    cloneStandoffShot(lat, lng, bearing, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Clone this shot?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          popup.remove();

          if (lat && lng) {
            this.showTools.standoff = true;

            this.standoffMouseClickEvent(
              { lngLat: { lat: lat, lng: lng } },
              "nodrag"
            );

            this.mouseClickCoordinate.bearing = bearing;
            this.mouseClickCoordinate.isClone = true;

            this.rotateStandoff(bearing);
          }
        }
      });
    },
    deleteStandoffShot(shotId, popup) {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this shot?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        confirmButtonColor: "red",
        focusCancel: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("toggle-spinner", true);

          popup.remove();

          const [call, err] = await this.Helper.handle(
            this.API.del(`site-standoffs/${shotId}`)
          );

          if (!err && call.status == 200) {
            Swal.fire("<h5 class='mb-0'>Shot deleted</h5>", "", "success").then(
              () => {
                const layers = this.map
                  .getStyle()
                  .layers.filter(
                    (layer) => layer.id == `standoffLayer-${shotId}`
                  );

                const sources = Object.keys(this.map.getStyle().sources).filter(
                  (source) => source == `standoffSource-${shotId}`
                );

                if (layers && layers.length > 0) {
                  layers.forEach((layer) => {
                    this.map.removeLayer(layer.id);
                  });
                }

                if (sources && sources.length > 0) {
                  sources.forEach((source) => {
                    this.map.removeSource(source);
                  });
                }

                this.standoffShots = this.standoffShots.filter(
                  (shot) => shot._id != shotId
                );

                this.$emit("toggle-spinner", false);
              }
            );
          }
        }
      });
    },
    async getStandoffShots() {
      this.$emit("toggle-spinner", true);

      const [call, err] = await this.Helper.handle(
        this.API.get(`site-standoffs?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.standoffShots = call.data.map((data) => {
          data.id = `standoffLayer-${data._id}`;
          data.show = true;

          return data;
        });
      }

      this.getPolygons();
    },
    async getPolygons() {
      const [call, err] = await this.Helper.handle(
        this.API.get(`site-polygons?site=${this.data._id}`)
      );

      if (!err && call.status == 200) {
        this.showAllPolygon = true;

        this.polygons = call.data.map((data) => {
          data.id = `polygonLayer:type-${data._id}`;
          data.show = true;

          return data;
        });
      }

      this.$nextTick(() => {
        this.initMap();
      });
    },
    toggleSpinner(e) {
      this.$emit("toggle-spinner", e);
    },
    closeAnnotationTool(e) {
      if (this.showTools.standoff) {
        this.showTools.standoff = false;

        this.mouseClickCoordinate = {};

        this.map.off("mousemove", this.mapCursorCrosshairEvent);

        this.map.off("click", this.standoffMouseClickEvent);

        if (this.standoffMarker) {
          this.standoffMarker.off("dragend", this.standoffMarkerDragEndEvent);

          this.standoffMarker.remove();

          this.standoffMarker = null;
        }

        if (e) {
          e.id = `standoffLayer-${e._id}`;

          e.show = true;

          this.standoffShots.push(e);

          this.addStandoffShotToMap(e);
        }
      } else if (this.showTools.polygon) {
        this.showTools.polygon = false;

        if (this.polygonDrawing) {
          this.map.off("draw.update", this.updatePolygonFeatures);

          this.map.off("draw.create", this.updatePolygonFeatures);

          this.map.removeControl(this.polygonDrawing);

          this.polygonDrawing = null;

          this.polygonFeatures = null;
        }

        if (e) {
          e.id = `polygonLayer:type-${e._id}`;

          e.show = true;

          this.polygons.push(e);

          this.addPolygonToMap(e);
        }
      }
    },
    closeLayerTool(e) {
      this.showTools.layer = false;

      if (e) {
        this.map.addSource(`tile-${this.mapLayers.length}`, {
          type: "raster",
          tiles: [`${e.url}{z}/{x}/{y}.png`],
          tileSize: 256,
          attribution:
            'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
        });

        this.map.addLayer({
          id: `tile-${this.mapLayers.length}`,
          type: "raster",
          source: `tile-${this.mapLayers.length}`,
          layout: {},
        });

        this.mapLayers.push({
          id: `tile-${this.mapLayers.length}`,
          name: e.name,
          date: e.date,
          tile: e,
          show: this.showAllTile,
          opacity: 100,
        });

        this.$emit("update-site-data", {
          field: "tiles",
          type: this.currentLayer ? "put" : "push",
          data: e,
        });
      }

      this.currentLayer = null;
    },
    mapCursorCrosshairEvent() {
      this.map.getCanvas().style.cursor = "crosshair";
    },
    mapCursorDefaultEvent() {
      this.map.getCanvas().style.cursor = "";
    },
    triggerAnnotationPrompt() {
      if (this.showTools.standoff || this.showTools.polygon) {
        this.closeAnnotationTool();
      } else {
        Swal.fire({
          icon: "warning",
          title: "<h5 class='mb-0'>Select annotation type</h5>",
          showDenyButton: true,
          showCloseButton: true,
          reverseButtons: true,
          denyButtonText: "Standoff / Drone shot",
          confirmButtonText: "Polygon",
        }).then((result) => {
          if (result.isConfirmed) {
            // Show polygon tool
            this.showTools.polygon = true;

            this.polygonDrawing = new MapboxDraw({
              displayControlsDefault: false,
              controls: {
                polygon: false,
                trash: false,
              },
              defaultMode: "draw_polygon",
            });

            this.map.addControl(this.polygonDrawing, "bottom-right");

            this.map.on("draw.update", this.updatePolygonFeatures);

            this.map.on("draw.create", this.updatePolygonFeatures);
          } else if (result.isDenied) {
            // Show standoff tool
            this.showTools.standoff = true;

            this.map.on("mousemove", this.mapCursorCrosshairEvent);

            this.map.on("click", this.standoffMouseClickEvent);
          }
        });
      }
    },
    updatePolygonFeatures() {
      this.polygonFeatures = this.polygonDrawing.getAll();
    },
    standoffMouseClickEvent(e, type) {
      this.mouseClickCoordinate = e.lngLat;

      if (this.standoffMarker) {
        this.standoffMarker.setLngLat(e.lngLat);
      } else {
        const el = document.createElement("div");

        el.className = "standoff-marker";
        el.style.backgroundImage = `linear-gradient(rgba(255, 244, 0, 0.5), rgba(255, 244, 0, 0.5)), url(${require("@/assets/icons/drone.png")}`;
        el.style.width = "30px";
        el.style.height = "30px";
        el.style.backgroundSize = "100%";
        el.style.borderRadius = "50%";

        this.standoffMarker = new mapboxgl.Marker({
          element: el,
          draggable: type == "nodrag" ? false : true,
        })
          .setLngLat(e.lngLat)
          .addTo(this.map);

        if (type !== "nodrag") {
          this.standoffMarker.on("dragend", this.standoffMarkerDragEndEvent);
        }
      }
    },
    standoffMarkerDragEndEvent() {
      const lngLat = this.standoffMarker.getLngLat();

      this.mouseClickCoordinate = lngLat;
    },
    togglePolygonVisibility(polygonId) {
      if (polygonId == "all") {
        this.polygons.forEach((polygon) => {
          this.adjustPolygonVisibility(polygon.id, "all");

          polygon.show = this.showAllPolygon;
        });
      } else {
        const polygon = this.polygons.find(
          (polygon) => polygon.id == polygonId
        );

        if (polygon) {
          this.adjustPolygonVisibility(polygon.id);
        }
      }
    },
    adjustPolygonVisibility(polygonId, type) {
      let visibility = this.showAllPolygon ? "visible" : "none";

      if (type != "all") {
        const polygon = this.polygons.find(
          (polygon) => polygon.id == polygonId
        );

        if (polygon) {
          visibility = polygon.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (this.polygons.filter((p) => p.show).length < this.polygons.length) {
          this.showAllPolygon = false;
        } else if (
          this.polygons.filter((p) => p.show).length == this.polygons.length
        ) {
          this.showAllPolygon = true;
        }
      }

      if (visibility !== null) {
        this.map.setLayoutProperty(
          polygonId.replace(":type", "Fill"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          polygonId.replace(":type", "Outline"),
          "visibility",
          visibility
        );
      }
    },
    toggleStandoffVisibility(standoffId) {
      if (standoffId == "all") {
        this.standoffShots.forEach((standoff) => {
          this.adjustStandoffVisibility(standoff.id, "all");

          standoff.show = this.showAllStandoff;
        });
      } else {
        const standoff = this.standoffShots.find(
          (standoff) => standoff.id == standoffId
        );

        if (standoff) {
          this.adjustStandoffVisibility(standoff.id);
        }
      }
    },
    adjustStandoffVisibility(standoffId, type) {
      let visibility = this.showAllStandoff ? "visible" : "none";

      if (type != "all") {
        const standoff = this.standoffShots.find(
          (standoff) => standoff.id == standoffId
        );

        if (standoff) {
          visibility = standoff.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (
          this.standoffShots.filter((s) => s.show).length <
          this.standoffShots.length
        ) {
          this.showAllStandoff = false;
        } else if (
          this.standoffShots.filter((s) => s.show).length ==
          this.standoffShots.length
        ) {
          this.showAllStandoff = true;
        }
      }

      if (visibility !== null) {
        if (
          this.map.getStyle().layers.find((layer) => layer.id == standoffId)
        ) {
          this.map.setLayoutProperty(standoffId, "visibility", visibility);
        }
      }
    },
    toggleGeojsonVisibility(geojsonId) {
      if (geojsonId == "all") {
        this.geojsons.forEach((geojson) => {
          this.adjustGeojsonVisibility(geojson.id, "all");

          geojson.show = this.showAllGeojson;
        });
      } else {
        const geojson = this.geojsons.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          this.adjustGeojsonVisibility(geojson.id);
        }
      }
    },
    adjustGeojsonVisibility(geojsonId, type) {
      let visibility = this.showAllGeojson ? "visible" : "none";

      if (type != "all") {
        const geojson = this.geojsons.find(
          (geojson) => geojson.id == geojsonId
        );

        if (geojson) {
          visibility = geojson.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (this.geojsons.filter((g) => g.show).length < this.geojsons.length) {
          this.showAllGeojson = false;
        } else if (
          this.geojsons.filter((g) => g.show).length == this.geojsons.length
        ) {
          this.showAllGeojson = true;
        }
      }

      if (visibility !== null) {
        this.map.setLayoutProperty(
          geojsonId.replace(":type", "fill"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "line"),
          "visibility",
          visibility
        );

        this.map.setLayoutProperty(
          geojsonId.replace(":type", "circle"),
          "visibility",
          visibility
        );
      }
    },
    toggleTileVisibility(layerId) {
      if (layerId == "all") {
        this.mapLayers.forEach((layer) => {
          layer.opacity = !this.showAllTile ? 0 : 100;

          layer.show = this.showAllTile;

          this.adjustTileVisibility(layer.id, "all");
        });
      } else {
        const layer = this.mapLayers.find((layer) => layer.id == layerId);

        if (layer) {
          layer.opacity = !layer.show ? 0 : 100;

          this.adjustTileVisibility(layerId);
        }
      }
    },
    adjustTileVisibility(layerId, type) {
      const layer = this.mapLayers.find((layer) => layer.id == layerId);

      let visibility = this.showAllTile ? "visible" : "none";

      if (type != "all") {
        if (layer) {
          visibility = layer.show ? "visible" : "none";
        } else {
          visibility = null;
        }

        if (
          this.mapLayers.filter((m) => m.show).length < this.mapLayers.length
        ) {
          this.showAllTile = false;
        } else if (
          this.mapLayers.filter((m) => m.show).length == this.mapLayers.length
        ) {
          this.showAllTile = true;
        }
      }

      if (visibility !== null) {
        const opacity = parseInt(layer.opacity, 10) / 100;

        this.map.setPaintProperty(layerId, "raster-opacity", opacity);
      }
    },
    measurementMouseMoveEvent(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // UI indicator for clicking/hovering a point on the map
      this.map.getCanvas().style.cursor = features.length
        ? "pointer"
        : "crosshair";
    },
    measurementMouseClickEvent(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: ["measure-points"],
      });

      // Remove the linestring from the group
      // So we can redraw it based on the points collection
      if (this.measurementGeoJson.features.length > 1) {
        this.measurementGeoJson.features.pop();
      }

      // Clear the Distance container to populate it with a new value
      this.measurement = 0;

      // If a feature was clicked, remove it from the map
      if (features.length) {
        let id = features[0].properties.id;

        this.measurementGeoJson.features =
          this.measurementGeoJson.features.filter(function (point) {
            return point.properties.id !== id;
          });
      } else {
        let point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        this.measurementGeoJson.features.push(point);
      }

      if (this.measurementGeoJson.features.length > 1) {
        this.measurementLineString.geometry.coordinates =
          this.measurementGeoJson.features.map(function (point) {
            return point.geometry.coordinates;
          });

        this.measurementGeoJson.features.push(this.measurementLineString);

        // // Populate the distanceContainer with total distance
        this.measurement = turf
          .length(this.measurementLineString)
          .toLocaleString();
      }

      this.map.getSource("measurementGeoJson").setData(this.measurementGeoJson);
    },
    removeMeasurement() {
      this.measurement = 0;

      this.measurementGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      this.measurementLineString = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };

      if (this.map) {
        this.map.removeLayer("measure-lines");
        this.map.removeLayer("measure-points");
        this.map.removeSource("measurementGeoJson");

        this.map.getCanvas().style.cursor = "";

        this.map.off("mousemove", this.measurementMouseMoveEvent);
        this.map.off("click", this.measurementMouseClickEvent);
      }
    },
    drawMeasurement() {
      // GeoJSON object to hold our measurement features
      this.measurementGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      // Used to draw a line between points
      this.measurementLineString = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };

      this.map.addSource("measurementGeoJson", {
        type: "geojson",
        data: this.measurementGeoJson,
      });

      // Add styles to the map
      this.map.addLayer({
        id: "measure-points",
        type: "circle",
        source: "measurementGeoJson",
        paint: {
          "circle-radius": 5,
          "circle-color": "#000",
        },
        filter: ["in", "$type", "Point"],
      });

      this.map.addLayer({
        id: "measure-lines",
        type: "line",
        source: "measurementGeoJson",
        layout: {
          "line-cap": "round",
          "line-join": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2.5,
        },
        filter: ["in", "$type", "LineString"],
      });

      this.map.on("click", this.measurementMouseClickEvent);

      this.map.on("mousemove", this.measurementMouseMoveEvent);
    },
    async addMapLayers() {
      const layers = this.map
        .getStyle()
        .layers.filter(
          (layer) =>
            layer.id == "boundaryLayer" ||
            layer.id.includes("tile-") ||
            layer.id.includes("geojsonLayer-") ||
            layer.id.includes("standoffLayer-") ||
            layer.id.includes("polygonLayer")
        );

      const sources = Object.keys(this.map.getStyle().sources).filter(
        (source) =>
          source == "boundarySource" ||
          source.includes("tile-") ||
          source.includes("geojsonSource-") ||
          source.includes("standoffSource-") ||
          source.includes("polygonSource-")
      );

      if (layers && layers.length > 0) {
        layers.forEach((layer) => {
          this.map.removeLayer(layer.id);
        });
      }

      if (sources && sources.length > 0) {
        sources.forEach((source) => {
          this.map.removeSource(source);
        });
      }

      this.mapLayers = [];

      // Add tiles
      if (this.data.tiles && this.data.tiles.length > 0) {
        await this.loadMapTiles();
      }

      // Add boundary
      if (this.data.boundary) {
        this.map.addSource("boundarySource", {
          type: "geojson",
          data: this.data.boundary.url,
        });

        this.map.addLayer({
          id: "boundaryLayer",
          type: "line",
          source: "boundarySource",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "stroke"],
            "line-width": 4,
          },
        });

        this.map.on("click", "boundaryLayer", (e) => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon
          ) {
            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(this.map);
          }
        });

        this.map.on("mouseenter", "boundaryLayer", () => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon
          ) {
            this.map.getCanvas().style.cursor = "pointer";
          }
        });

        this.map.on("mouseleave", "boundaryLayer", () => {
          if (
            !this.showTools.measurement &&
            !this.showTools.standoff &&
            !this.showTools.polygon
          ) {
            this.map.getCanvas().style.cursor = "";
          }
        });
      }

      // Add geojson
      this.geojsons = [];

      if (this.data.geojson && this.data.geojson.length > 0) {
        this.data.geojson.forEach((geojson, index) => {
          this.map.addSource(`geojsonSource-${index}`, {
            type: "geojson",
            data: geojson.url,
          });

          this.map.addLayer({
            id: `geojsonLayer-fill-${index}`,
            type: "fill",
            source: `geojsonSource-${index}`,
            layout: {},
            paint: {
              "fill-color": this.Helper.colors(index),
              "fill-opacity": 0.5,
            },
            filter: ["==", "$type", "Polygon"],
          });

          this.map.addLayer({
            id: `geojsonLayer-line-${index}`,
            type: "line",
            source: `geojsonSource-${index}`,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": this.Helper.colors(index),
              "line-width": 4,
            },
            filter: ["==", "$type", "Polygon"],
          });

          this.map.addLayer({
            id: `geojsonLayer-circle-${index}`,
            type: "circle",
            source: `geojsonSource-${index}`,
            paint: {
              "circle-radius": 6,
              "circle-color": this.Helper.colors(index),
            },
            filter: ["==", "$type", "Point"],
          });

          this.map.on("click", `geojsonLayer-fill-${index}`, (e) => {
            this.geojsonOnClickAction(e, geojson);
          });

          this.map.on("mouseenter", `geojsonLayer-fill-${index}`, () => {
            this.geojsonOnMouseEnterAction("pointer");
          });

          this.map.on("mouseleave", `geojsonLayer-fill-${index}`, () => {
            this.geojsonOnMouseEnterAction("");
          });

          this.map.on("click", `geojsonLayer-circle-${index}`, (e) => {
            this.geojsonOnClickAction(e, geojson);
          });

          this.map.on("mouseenter", `geojsonLayer-circle-${index}`, () => {
            this.geojsonOnMouseEnterAction("pointer");
          });

          this.map.on("mouseleave", `geojsonLayer-circle-${index}`, () => {
            this.geojsonOnMouseEnterAction("");
          });

          this.geojsons.push({
            id: `geojsonLayer-:type-${index}`,
            name: geojson.name,
            show: true,
            lineColor: this.Helper.colors(index),
            opacity: 100,
          });
        });
      }

      // Add standoff shots
      this.map.loadImage(
        require("@/assets/icons/drone.png"),
        (error, image) => {
          if (error) throw error;

          this.map.addImage("drone", image);

          if (this.standoffShots.length > 0) {
            this.standoffShots.forEach((shot) => {
              this.addStandoffShotToMap(shot);
            });
          }
        }
      );

      // Add polygons
      if (this.polygons.length > 0) {
        this.polygons.forEach((polygon) => {
          this.addPolygonToMap(polygon);
        });
      }

      this.toggleGeojsonVisibility("all");

      this.toggleTileVisibility("all");

      this.toggleStandoffVisibility("all");

      this.togglePolygonVisibility("all");
    },
    loadMapTiles() {
      return new Promise(async (resolve) => {
        let bounds = new mapboxgl.LngLatBounds();

        const tilePromises = [];

        this.data.tiles.forEach((tile, index) => {
          tilePromises.push(
            new Promise((resolve) => {
              this.API.testExternal(tile.url.concat("index.html"))
                .then(() => {
                  if (tile.nw_bounds) {
                    bounds.extend(tile.nw_bounds.split(","));
                  }

                  if (tile.ne_bounds) {
                    bounds.extend(tile.ne_bounds.split(","));
                  }

                  if (tile.se_bounds) {
                    bounds.extend(tile.se_bounds.split(","));
                  }

                  if (tile.sw_bounds) {
                    bounds.extend(tile.sw_bounds.split(","));
                  }

                  this.map.addSource(`tile-${index}`, {
                    type: "raster",
                    tiles: [`${tile.url}{z}/{x}/{y}.png`],
                    tileSize: 256,
                    attribution:
                      'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
                  });

                  this.map.addLayer({
                    id: `tile-${index}`,
                    type: "raster",
                    source: `tile-${index}`,
                    layout: {},
                  });

                  this.mapLayers.push({
                    id: `tile-${index}`,
                    name: tile.name,
                    date: tile.date,
                    tile: tile,
                    show: true,
                    opacity: 100,
                  });

                  if (Object.keys(bounds).length > 0) {
                    this.map.fitBounds(bounds, {
                      padding: 20,
                      duration: 0,
                    });
                  }

                  return;
                })
                .catch(() => {
                  console.error(`Invalid Tile URL: ${tile.url}`);
                })
                .finally(() => {
                  resolve(index);
                });
            })
          );
        });

        await Promise.all(tilePromises);

        resolve();
      });
    },
    geojsonOnMouseEnterAction(cursor) {
      if (
        !this.showTools.measurement &&
        !this.showTools.standoff &&
        !this.showTools.polygon &&
        !this.showTools.pin
      ) {
        this.map.getCanvas().style.cursor = cursor;
      }
    },
    geojsonOnClickAction(e, geojson) {
      if (
        !this.showTools.measurement &&
        !this.showTools.standoff &&
        !this.showTools.polygon &&
        !this.showTools.pin
      ) {
        let gjsonFeatures = "";

        Object.keys(e.features[0].properties).forEach((feature) => {
          gjsonFeatures += `<tr>
                  <td>${feature}</td>
                  <td>${e.features[0].properties[feature]}</td>
                </tr>`;
        });

        let popupContainer = `<div class="mapbox-popup-container">
                <p class="popup-header">
                  ${this.Helper.formatGeojsonName(geojson.name)}
                </p>
                <div class="popup-content">
                  <table class="table table-bordered table-striped table-sm mb-0">
                    ${gjsonFeatures}
                  </table>
                </div>
              </div>`;

        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(popupContainer)
          .addTo(this.map);
      }
    },
    initMap() {
      return new Promise(async (resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        let mapCenter = [101.5179483, 3.075603444131706];

        if (this.data.lat && this.data.lng) {
          mapCenter = [this.data.lng, this.data.lat];
        }

        this.map = new mapboxgl.Map({
          container: "site-map",
          style: this.Helper.mapStyle(),
          center: mapCenter,
          zoom: 17,
        });

        this.map.on("load", async () => {
          this.showMapButton = true;

          resolve();
        });

        this.map.on("style.load", async () => {
          this.addMapLayers();

          this.$emit("toggle-spinner", false);
        });
      });
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();

      this.map = null;
    }
  },
};
</script>