<template>
  <div class="mapbox-popup-container">
    <div
      class="
        popup-header
        line-height-sm
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <span>
        {{ data.name || "Standoff / Drone Shot" }}<br />
        <small class="text-muted">{{ data.date }}</small>
      </span>
      <div>
        <button
          class="btn btn-light btn-sm mr-2"
          data-toggle="tooltip"
          title="Clone this shot"
          @mouseenter="Helper.showTooltip($event)"
          @click="cloneShot(data.lat, data.lng, data.bearing)"
        >
          <i class="far fa-copy" />
        </button>
        <button
          class="btn btn-danger btn-sm"
          data-toggle="tooltip"
          title="Delete this shot"
          @mouseenter="Helper.showTooltip($event)"
          @click="deleteShot(data._id)"
        >
          <i class="fas fa-trash-alt" />
        </button>
      </div>
    </div>
    <div class="popup-content">
      <img
        class="w-100 h-auto clickable"
        :src="imageUrl"
        @click="viewImage(data.media)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    imageUrl() {
      if (this.data) {
        if (
          this.data.media &&
          this.data.media.formats &&
          this.data.media.formats.thumbnail
        ) {
          return this.Helper.formatMediaUrl(this.data.media.formats.thumbnail);
        } else if (this.data.media) {
          return this.Helper.formatMediaUrl(this.data.media);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (Math.ceil(this.$el.clientWidth) % 2) {
      this.$el.style.width = Math.ceil(this.$el.clientWidth) + 1 + "px";
    }
  },
};
</script>