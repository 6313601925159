var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-fullscreen has-navbar has-footer position-relative layout-split-side"},[_c('div',{staticClass:"side-right"},[_c('div',{staticClass:"side-right-header bg-dark font-weight-bold shadow-sm d-flex justify-content-between align-items-center border-top border-default-dark"},[_c('ul',{staticClass:"nav site-nav h-100"},_vm._l((_vm.categories.filter((c) => c.show)),function(category){return _c('li',{key:category.name,staticClass:"nav-item",class:{
          active: _vm.currentCategory && category.name == _vm.currentCategory.name,
        }},[_c('button',{staticClass:"btn h-100 font-weight-bold",on:{"click":function($event){_vm.currentCategory = category}}},[_c('i',{staticClass:"fas icon",class:`fa-${category.icon}`}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(category.label))])])])}),0)]),_c('div',{staticClass:"side-right-content"},[(_vm.currentCategory && _vm.currentCategory.name == 'temporal' && _vm.site)?_c('temporal',{attrs:{"data":_vm.site},on:{"toggle-spinner":_vm.toggleSpinner}}):(_vm.currentCategory && _vm.currentCategory.name == 'media_gallery')?_c('site-gallery',{attrs:{"siteName":_vm.site.name},on:{"toggle-spinner":_vm.toggleSpinner}}):(_vm.currentCategory && _vm.currentCategory.name == 'files')?_c('files',{attrs:{"siteName":_vm.site.name,"site":_vm.site},on:{"toggle-spinner":_vm.toggleSpinner}}):(
        _vm.currentCategory && _vm.currentCategory.name == 'point_cloud' && _vm.site
      )?_c('point-cloud',{attrs:{"siteId":_vm.site._id,"data":_vm.site.point_cloud},on:{"update-site-data":_vm.updateSiteData,"toggle-spinner":_vm.toggleSpinner}}):(
        _vm.currentCategory && _vm.currentCategory.name == 'orthomosaic' && _vm.site
      )?_c('orthomosaic',{attrs:{"data":_vm.site},on:{"update-site-data":_vm.updateSiteData,"toggle-spinner":_vm.toggleSpinner}}):(_vm.currentCategory && _vm.currentCategory.name == 'report')?_c('report',{attrs:{"data":_vm.site},on:{"toggle-spinner":_vm.toggleSpinner}}):_vm._e()],1)]),_c('spinner',{attrs:{"show":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }