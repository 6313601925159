<template>
  <div class="modal-custom">
    <div class="modal-custom-content">
      <div class="modal-custom-header">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <h1 class="d-flex justify-content-start align-items-center">
              {{ report ? "Edit" : "Add" }} Report
            </h1>
          </div>
        </div>
        <button class="btn border shadow-sm btn-close" @click="closePreview()">
          &times;
        </button>
      </div>
      <div class="modal-custom-body">
        <div class="form-group">
          <label for="reportSite">Site<sup class="text-danger">*</sup></label>
          <div class="form-control" readonly>
            {{ models.site ? models.site.name : "" }}
          </div>
        </div>
        <div class="form-group">
          <label for="reportName">
            Report Title<sup class="text-danger">*</sup>
          </label>
          <input
            id="reportName"
            type="text"
            class="form-control"
            v-model="models.name"
          />
        </div>
        <div class="form-group">
          <label for="reportScope">
            Scope<sup class="text-danger">*</sup>
          </label>
          <input
            id="reportScope"
            type="text"
            class="form-control"
            v-model="models.scope"
          />
        </div>
        <div class="form-group">
          <label for="reportIssue">
            Issue<sup class="text-danger">*</sup>
          </label>
          <input
            id="reportIssue"
            type="text"
            class="form-control"
            v-model="models.issue"
          />
        </div>
        <div class="form-group">
          <label for="reportSeverity">Severity</label>
          <select class="custom-select" v-model="models.severity">
            <option
              v-for="opt in options.severity"
              :key="`severity-${opt}`"
              :value="opt"
            >
              {{ opt }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="reportAction">Action</label>
          <input
            id="reportAction"
            type="text"
            class="form-control"
            v-model="models.action"
          />
        </div>
        <div class="form-group">
          <label for="reportRemarks">Additional Remarks</label>
          <input
            id="reportRemarks"
            type="text"
            class="form-control"
            v-model="models.remarks"
          />
        </div>
        <div class="form-group">
          <label for="reportMedia">
            Media
            <small>(Max 2 images)</small>
          </label>
          <template>
            <div
              class="gallery-group p-0 d-flex align-items-start overflow-auto"
            >
              <div
                class="gallery-item p-0 mr-3 rounded position-relative hover-parent"
                v-for="(media, index) in models.media"
                :key="`media-upload-${index}`"
              >
                <button
                  class="btn btn-sm btn-danger position-absolute hover-item"
                  style="top: 0.25rem; right: 0.25rem; z-index: 2"
                  @click.stop="removeMedia(index)"
                >
                  <i class="fas fa-trash-alt" />
                </button>
                <div
                  class="embed-responsive rounded embed-responsive-1by1 shadow-sm bg-dark"
                >
                  <img
                    :src="media.preview"
                    class="embed-responsive-item rounded clickable"
                    @click="
                      currentMedia = { url: media.preview, mime: 'image' }
                    "
                  />
                </div>
              </div>
              <div
                class="gallery-item p-0 rounded position-relative hover-parent"
                v-if="models.media.length < 2"
              >
                <div
                  class="embed-responsive rounded embed-responsive-1by1 shadow-sm bg-dark"
                >
                  <button
                    class="btn btn-dark text-default-dark embed-responsive-item rounded d-flex flex-column justify-content-center align-items-center font-weight-medium text-center line-height-sm p-2"
                    @click="addMedia()"
                  >
                    <i class="fas fa-plus mb-1" />
                    <small>Add</small>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-group text-right" v-if="!hasPolygon">
          <button
            class="btn btn-dark btn-sm text-default-dark"
            @click="hasPolygon = 'true'"
          >
            <i class="fas fa-draw-polygon mr-1" />
            Draw Polygon
          </button>
        </div>
        <div class="form-group" v-if="hasPolygon">
          <label
            for="reportPolygon"
            class="d-flex justify-content-between align-items-center"
          >
            Polygon
            <button class="btn btn-sm btn-light" @click="hasPolygon = false">
              Cancel
            </button>
          </label>
          <div class="embed-responsive embed-responsive-4by3">
            <div class="embed-responsive-item">
              <div id="report-map" class="w-100 h-100 position-relative"></div>
            </div>
          </div>

          <!-- <div class="embed-responsive embed-responsive-4by3">
            <div class="embed-responsive-item">
              <img :src="canvasImage" class="w-100 h-100 position-relative" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-custom-footer">
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-sm btn-light" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-sm btn-primary" @click="saveReport()">
            Save
          </button>
        </div>
      </div>
    </div>

    <media-preview
      v-if="currentMedia"
      :media="currentMedia"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MediaPreview from "@/components/MediaPreview";

export default {
  props: ["report", "site"],
  components: { MediaPreview },
  data() {
    return {
      map: null,
      currentMedia: null,
      hasPolygon: false,
      polygonDrawing: null,
      drawnPolygon: null,
      canvasImage: null,
      mapTiles: [],
      options: {
        severity: ["Low", "Normal", "Medium", "High"],
      },
      models: {
        name: null,
        user: null,
        site: null,
        scope: null,
        issue: null,
        action: null,
        severity: null,
        remarks: null,
        polygon: null,
        media: [],
        date: null,
        status: null,
      },
    };
  },
  watch: {
    hasPolygon() {
      if (this.hasPolygon) {
        this.$nextTick(() => {
          this.initMap();
        });
      } else {
        this.clearMap();
      }
    },
  },
  methods: {
    initMap() {
      return new Promise(async (resolve) => {
        this.$emit("toggle-spinner", true);

        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        let mapCenter = [101.5179483, 3.075603444131706];

        if (this.site && this.site.lat && this.site.lng) {
          mapCenter = [this.site.lng, this.site.lat];
        }

        this.map = new mapboxgl.Map({
          container: "report-map",
          style: this.Helper.mapStyle(),
          center: mapCenter,
          zoom: 14,
        });

        this.map.addControl(new mapboxgl.FullscreenControl());

        this.map.on("load", () => {
          this.addMapTiles();

          resolve();
        });

        this.map.on("style.load", async () => {
          this.addPolygonControl();

          this.$emit("toggle-spinner", false);
        });
      });
    },
    addPolygonControl() {
      this.polygonDrawing = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: "draw_polygon",
      });

      this.map.addControl(this.polygonDrawing, "bottom-right");

      this.map.on("draw.update", this.updatePolygonFeatures);

      this.map.on("draw.create", this.updatePolygonFeatures);
    },
    updatePolygonFeatures(e) {
      this.drawnPolygon = this.polygonDrawing.getAll();
    },
    addPolygonToMap(polygon) {
      this.map.addSource("polygonSource", {
        type: "geojson",
        data: polygon,
      });

      this.map.addLayer({
        id: "polygonLayerFill",
        type: "fill",
        source: "polygonSource",
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });

      this.map.addLayer({
        id: "polygonLayerOutline",
        type: "line",
        source: "polygonSource",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 3,
        },
      });

      this.map.moveLayer("polygonLayerFill");
    },
    addMapTiles() {
      const layers = this.map
        .getStyle()
        .layers.filter((layer) => layer.id.includes("tile-"));

      const sources = Object.keys(this.map.getStyle().sources).filter(
        (source) => source.includes("tile-")
      );

      if (layers && layers.length > 0) {
        layers.forEach((layer) => {
          this.map.removeLayer(layer.id);
        });
      }

      if (sources && sources.length > 0) {
        sources.forEach((source) => {
          this.map.removeSource(source);
        });
      }

      this.mapTiles = [];

      let siteTiles =
        this.site && this.site.tiles && this.site.tiles.length > 0
          ? JSON.parse(JSON.stringify(this.site.tiles))
          : [];

      siteTiles.sort((a, b) => {
        const aDate = a.date ? this.Helper.moment(a.date).unix() : 0;
        const bDate = bDate ? this.Helper.moment(b.date).unix() : 0;

        return bDate - aDate;
      });

      // Add tiles
      if (siteTiles && siteTiles.length > 0) {
        let bounds = new mapboxgl.LngLatBounds();

        let tile = siteTiles[0];

        this.API.testExternal(tile.url)
          .then(() => {
            if (tile.nw_bounds) {
              bounds.extend(tile.nw_bounds.split(","));
            }

            if (tile.ne_bounds) {
              bounds.extend(tile.ne_bounds.split(","));
            }

            if (tile.se_bounds) {
              bounds.extend(tile.se_bounds.split(","));
            }

            if (tile.sw_bounds) {
              bounds.extend(tile.sw_bounds.split(","));
            }

            this.map.addSource("tile-report", {
              type: "raster",
              tiles: [`${tile.url}{z}/{x}/{y}.png`],
              tileSize: 256,
              attribution:
                'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>',
            });

            this.map.addLayer(
              {
                id: "tile-report",
                type: "raster",
                source: "tile-report",
              },
              "gl-draw-polygon-fill-inactive.cold"
            );

            this.mapTiles.push({
              id: "tile-report",
              name: tile.name,
              date: tile.date,
              show: true,
              opacity: 100,
            });
          })
          .catch(() => {
            console.error(`Invalid Tile URL: ${tile.url}`);
          })
          .finally(() => {
            if (this.models.polygon) {
              this.addPolygonToMap(this.models.polygon);
            }
          });

        if (Object.keys(bounds).length > 0) {
          this.map.fitBounds(bounds, {
            padding: 20,
            duration: 0,
          });
        }
      }
    },
    removeMedia(index) {
      this.models.media = this.models.media.filter((m, idx) => idx != index);
    },
    addMedia() {
      let uploader = document.createElement("input");

      uploader.type = "file";
      uploader.accept = "image/*";
      uploader.multiple = false;

      uploader.onchange = (event) => {
        if (event.target.files.length < 1) return;

        let file = event.target.files[0];

        if (file.type.includes("image")) {
          let reader = new FileReader();

          reader.readAsDataURL(file);

          reader.onload = (readerEvent) => {
            this.$nextTick(() => {
              this.models.media.push({
                file: file,
                preview: readerEvent.target.result,
                type: "image",
              });
            });
          };
        }
      };

      uploader.click();
    },
    async saveReport() {
      // Save report
      if (
        !this.models.site ||
        !this.models.name ||
        !this.models.scope ||
        !this.models.issue ||
        !this.models.severity
      ) {
        Swal.fire({
          title: "<h5>Error</h5>",
          html: "<div class='text-muted'>Error submitting report</div>",
          icon: "error",
        });
      } else {
        this.$emit("toggle-spinner", true);

        const formData = new FormData();

        const data = {};

        let deletedMedias = [];

        Object.keys(this.models).forEach((key) => {
          if (key !== "media") {
            if (["site", "user"].includes(key)) {
              data[key] = this.models[key] ? this.models[key]._id : null;
            } else if (key == "polygon") {
              let polygonModel = this.models[key];

              if (polygonModel && this.drawnPolygon) {
                polygonModel.features = polygonModel.features.concat(
                  this.drawnPolygon.features
                );
              } else {
                polygonModel = this.drawnPolygon;
              }

              data[key] = polygonModel;
            } else {
              data[key] = this.models[key]
                ? this.models[key].toString().trim()
                : null;
            }
          } else {
            if (this.report) {
              deletedMedias = this.report.media.filter(
                (rm) =>
                  !this.models[key]
                    .filter((m) => m.id)
                    .map((m) => m.id)
                    .includes(rm._id)
              );
            }

            const newMedias = this.models[key].filter((m) => m.file);

            for (let i = 0; i < newMedias.length; i++) {
              const file = newMedias[i].file;

              formData.append(`files.${key}`, file, file.name);
            }
          }
        });

        formData.append("data", JSON.stringify(data));

        for (let i = 0; i < deletedMedias.length; i++) {
          await this.API.del(`upload/files/${deletedMedias[i].id}`);
        }

        let method = "postForm",
          url = "reports",
          emitType = "close",
          message = "submitted";

        if (this.report) {
          method = "putForm";

          url += `/${this.report.id}`;

          emitType += "-edit";

          message = "updated";
        }

        const [call, err] = await this.Helper.handle(
          this.API[method](url, formData)
        );

        this.$emit("toggle-spinner", false);

        if (!err && call.status == 200) {
          Swal.fire({
            title: `<h5>Report ${message}</h5>`,
            icon: "success",
          }).then(() => {
            this.$nextTick(() => {
              this.$emit(emitType, call.data);
            });
          });
        } else {
          Swal.fire({
            title: "<h5>Error</h5>",
            html: "<div class='text-muted'>Error submitting report</div>",
            icon: "error",
          });
        }
      }
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    clearMap() {
      if (this.map) {
        this.map.off("draw.update", this.updatePolygonFeatures);

        this.map.off("draw.create", this.updatePolygonFeatures);

        this.map.removeControl(this.polygonDrawing);

        this.map.remove();

        this.map = null;
      }
    },
  },
  mounted() {
    this.models = {
      name: null,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      site: this.site,
      scope: null,
      issue: null,
      action: null,
      severity: "Low",
      remarks: null,
      polygon: null,
      media: [],
      date: new Date(),
      status: "New",
    };

    if (this.report) {
      Object.keys(this.report).forEach((key) => {
        if (this.models.hasOwnProperty(key)) {
          if (key == "media") {
            this.models.media = this.report[key].map((media) => {
              return {
                id: media._id,
                file: null,
                preview: media.url,
                type: "image",
              };
            });
          } else {
            this.models[key] = this.report[key];
          }
        }
      });

      if (this.models.polygon) {
        this.hasPolygon = true;

        // this.canvasImage = `https://api.mapbox.com/styles/v1/mapbox/${this.Helper.mapStyle(
        //   false,
        //   true
        // )}/static${siteMarkers}/auto/500x375@2x?padding=0, 50&access_token=${
        //   process.env.VUE_APP_MAPBOX_KEY
        // }`;
      }
    }
  },
  beforeDestroy() {
    this.clearMap();

    this.closePreview();
  },
};
</script>