<template>
  <div class="site-gallery container-fluid pt-0">
    <div class="position-relative py-3">
      <div class="card mb-1" v-for="file in files">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <label class="text-uppercase">{{ Helper.splitUnderscore(file.key?.replace("_file", "")) }}</label>
            <button class="btn btn-primary btn-sm" @click="showUploadModal = file.key">
              <i class="fas fa-plus mr-1"></i>
              Upload
            </button>
          </div>

          <div class="table-responsive" v-if="file.files?.length">
            <table class="table table-striped table-bordered table-dark">
              <thead>
                <tr>
                  <th class="w-1 text-center">#</th>
                  <th>Name</th>
                  <th class="w-1 text-center">View</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(fl, index) in file.files">
                  <td class="w-1 text-center">{{ index + 1 }}</td>
                  <td>{{ fl.name }}</td>
                  <td class="w-1 text-center">
                    <button class="btn btn-primary btn-sm text-nowrap" @click="currentFile = fl" v-if="fl.url">
                      <i class="fas fa-eye mr-1"></i>
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col py-5 text-center" v-else>No file</div>
        </div>
      </div>
    </div>

    <file-upload-modal @close="showUploadModal = false" @reload="reloadList" :siteId="site?.id"
      :type="showUploadModal" />
    <media-preview v-if="currentFile"
      :title="`[${Helper.splitUnderscore(currentFile.key?.replace('_file', ''))?.toUpperCase()}] ${currentFile.name}`"
      :subtitle="siteName" :media="currentFile" :hasNavigation="false" @close="currentFile = null" />
  </div>
</template>

<script>
import MediaPreview from "@/components/MediaPreview";
import FileUploadModal from "@/components/FileUploadModal";

export default {
  name: "component-site-files",
  props: ["siteName", "site"],
  components: {
    MediaPreview,
    FileUploadModal
  },
  data() {
    return {
      files: [],
      currentFile: null,
      showUploadModal: false
    };
  },
  watch: {
    site: {
      handler() {
        if (this.site) {
          this.files = [];

          Object.keys(this.site).forEach(key => {
            if (key.endsWith('_file')) {
              this.files.push({
                files: this.site[key].map(file => {
                  file.key = key

                  return file;
                }), key: key
              });
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
    showUploadModal: {
      handler() {
        if (this.showUploadModal) {
          return this.Helper.showModal("#fileUploadModal");
        } else {
          this.Helper.hideModal("#fileUploadModal");
        }
      },
    }
  },
  methods: {
    reloadList({ key, file } = {}) {
      if (key && file) {
        const item = this.files.find(file => file.key == key);

        if (item.files) item.files.push(file);
      }
    }
  }
};
</script>